/* istanbul ignore file */

import Keycloak from 'keycloak-js';
import { APIENV, getCustomiserState } from '@europrocurement/l2d-domain';

// eslint-disable-next-line import/prefer-default-export

export const keycloak = new Keycloak({
    ...APIENV.keycloak[getCustomiserState().env],
    clientId: 'legal2digital-formalite',
});

export const updateToken = () => keycloak.updateToken(10).then(() => keycloak.token || '');
