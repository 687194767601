import React, { FunctionComponent } from 'react';
import { Box, Button, Popover, Typography, useTheme } from '@mui/material';
import { DateRange } from '@europrocurement/flexy-components/components/molecules/DateRangePicker';
import { FlexyFilterWrapperProps } from '../DatatableHeader';

const FlexyDateRangeWrapper: FunctionComponent<FlexyFilterWrapperProps> = function (props) {
    const { id, anchorEl, open, onCancel, onSubmit, children, value } = props;
    const theme = useTheme();

    const handleClose = () => {
        onCancel();
    };

    const handleSubmit = () => {
        onSubmit();
    };

    const btnStyle = {
        padding: '8px 24px',
        borderRadius: '12px',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '24px',
        textAlign: 'center',
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                marginTop: '10px',
            }}
        >
            <Box
                sx={{
                    borderRadius: '12px',
                    minWidth: '823px',
                    boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    sx={{
                        padding: '26px 34px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: theme.palette.text.light,
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '25.2px',
                            textAlign: 'left',
                        }}
                    >
                        Sélectionnez une plage de date
                    </Typography>
                    {value && (
                        <Typography
                            component="span"
                            sx={{
                                color: theme.palette.common.black,
                                fontFamily: 'Inter',
                                fontSize: '22px',
                                fontWeight: '500',
                                lineHeight: '26.4px',
                                textAlign: 'left',
                            }}
                        >
                            {(value as DateRange)?.startDate?.toLocaleDateString('fr-FR', {
                                month: 'long',
                                day: 'numeric',
                            })}
                            &nbsp;-&nbsp;
                            {(value as DateRange)?.endDate?.toLocaleDateString('fr-FR', {
                                month: 'long',
                                day: 'numeric',
                            })}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ padding: '8px 0 8px 34px' }}>{children}</Box>
                <Box
                    sx={{
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '0 32px',
                        boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        color="secondary"
                        sx={btnStyle}
                    >
                        Fermer
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        color="secondary"
                        sx={btnStyle}
                    >
                        Voir les résultats
                    </Button>
                </Box>
            </Box>
        </Popover>
    );
};

export default FlexyDateRangeWrapper;
