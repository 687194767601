import React, { FunctionComponent } from 'react';
import { Button, Typography } from '@mui/material';

export type FlexyPreFilterProps = {
    label: string;
    current: boolean;
    count?: number;
    onClick: () => void;
};

const FlexyPreFilter: FunctionComponent<FlexyPreFilterProps> = function (props) {
    const { label, current, count, onClick } = props;

    return (
        <Button
            sx={{
                borderBottomStyle: 'solid',
                borderBottomWidth: '2px',
                borderBottomColor: current ? 'primary.main' : 'transparent',
                borderRadius: 0,
                padding: '8px 0',
                minWidth: 0,
                display: 'flex',
                gap: '8px',
            }}
            onClick={onClick}
        >
            <Typography
                sx={{
                    fontSize: '15px',
                    fontWeight: current ? '600' : '500',
                    lineHeight: '24px',
                    color: current ? 'rgba(10, 26, 83, 1)' : 'rgba(10, 26, 83, 0.5)',
                }}
                component="span"
            >
                {label}
            </Typography>
            {count !== undefined && (
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontWeight: '500',
                        lineHeight: '24px',
                        color: 'rgba(10, 26, 83, 0.5)',
                    }}
                    component="span"
                >
                    {count}
                </Typography>
            )}
        </Button>
    );
};

export default FlexyPreFilter;
