import { FunctionComponent } from 'react';
import { FlexyFilterWrapperProps } from '../DatatableHeader';

const FlexyFilterCollapsed: FunctionComponent<FlexyFilterWrapperProps> = function (props) {
    const { children } = props;

    return children;
};

export default FlexyFilterCollapsed;
