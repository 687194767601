import React, { FunctionComponent } from 'react';
import { List, ListItem, PaletteColor, useTheme } from '@mui/material';
import { StepperStep, StepperStepExtendedProps as SingleStepProps } from '../../molecules';
import { ProgressBar } from '../../atoms';
import './Stepper.css';

export type StepperStepProps = {
    name: string;
    text: string;
    progress?: number;
    onClick?: (name: string) => void;
    color?: PaletteColor;
};

export type StepperProps = {
    layout?: 'horizontal' | 'vertical';
    steps: Array<StepperStepProps>;
    color?: PaletteColor;
};

const Stepper: FunctionComponent<StepperProps> = function (props: StepperProps) {
    const theme = useTheme();
    const { layout = 'horizontal', steps, color } = props;
    const mainColor = color || theme.palette.primary;
    let shouldDisable: boolean = false;

    const renderedSteps = steps.map((step: StepperStepProps, idx: number) => {
        const completion = step.progress !== undefined ? step.progress : 30;
        const stepProp: SingleStepProps = {
            name: step.name,
            label: step.text,
            index: idx + 1,
            done: (step.progress || 0) >= 100,
            disabled: shouldDisable,
            layout,
            onClick: step.onClick || (() => {}),
            color: step.color || mainColor,
        };
        shouldDisable = idx > 0 && !stepProp.done;
        let completionBar = null;

        if (idx < steps.length - 1) {
            completionBar = (
                <ProgressBar
                    sx={{
                        minWidth: '50px',
                    }}
                    completion={completion}
                    color={step.color || mainColor}
                    minCompletion={0}
                />
            );
        }

        return (
            <ListItem
                data-testid={step.name}
                key={step.name}
                sx={{
                    paddingRight: '0px',
                }}
            >
                <StepperStep {...stepProp} />
                {completionBar}
            </ListItem>
        );
    });

    return (
        <List
            data-testid="stepper"
            className="stepper"
        >
            {renderedSteps}
        </List>
    );
};

export default Stepper;
