import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons';
import { useTheme } from '@mui/system';

export type Dirigeant = {
    fonction: string;
    nom: string;
    prenom: string;
};

// export type Benef = Dirigeant & {
//     parts : number
// }

export type DirigeantWidgetType = {
    dirigeant: Dirigeant;
    actions?: Array<{
        name: string;
        icon?: React.ReactNode | React.FunctionComponent;
        tooltip?: string;
        text?: string;
        onClick: (dirigeant: Dirigeant) => void;
    }>;
};

// exemple de style sur DisplayFile

const DirigeantWidget: React.FunctionComponent<DirigeantWidgetType> = function ({
    dirigeant,
    actions,
}: DirigeantWidgetType) {
    const theme = useTheme();
    return (
        <Card sx={{ padding: '10px', cursor: 'pointer' }}>
            <Grid
                container
                sx={{
                    marginLeft: '0px',
                }}
            >
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        paddingLeft: '0px',
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap="10px"
                        sx={{
                            margin: '0 12px 0',
                        }}
                    >
                        <Box
                            data-testid="test-id-status-color-box"
                            sx={{
                                margin: '0px',
                                padding: '10px',
                                borderRadius: '10px',
                                fontSize: '25px',
                                maxHeight: '55px',
                                backgroundColor: 'rgba(66,221,245,0.2)',
                            }}
                        >
                            <FaOptionIcon
                                data-testid="test-id-mimetype-icone"
                                icon={faUserTie}
                                color={theme.palette.primary.main}
                                sx={{ fontSize: '20px' }}
                            />
                        </Box>
                        <Box sx={{ padding: '0 10px', minWidth: '150px' }}>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 'bold' }}
                            >
                                {`${dirigeant.prenom} ${dirigeant.nom}`}
                            </Typography>
                            <Typography variant="h5">{dirigeant.fonction}</Typography>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap="10px"
                        sx={{
                            margin: '0 0 0 auto',
                        }}
                    >
                        {actions
                            ? actions.map((action, key) => (
                                  <Box
                                      title={action.tooltip}
                                      data-testid={`test-id-${key}-handleClick-button`}
                                      onClick={() => action.onClick(dirigeant)}
                                      role="button"
                                      key={action.name}
                                  >
                                      {action.icon || action.text}{' '}
                                  </Box>
                              ))
                            : null}
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default DirigeantWidget;
