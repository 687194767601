import React, { FunctionComponent } from 'react';
import { Box, Button, Popover } from '@mui/material';
import { FlexyFilterWrapperProps } from '../DatatableHeader';

const FlexyFilterExpandable: FunctionComponent<FlexyFilterWrapperProps> = function (props) {
    const { id, anchorEl, open, onCancel, onSubmit, children } = props;

    const handleClose = () => {
        onCancel();
    };

    const handleSubmit = () => {
        onSubmit();
    };

    const btnStyles = {
        padding: '8px 24px',
        borderRadius: '12px',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '24px',
        textAlign: 'center',
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                marginTop: '10px',
            }}
        >
            <Box
                sx={{
                    borderRadius: '12px',
                    minWidth: '372px',
                    boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                }}
            >
                {children}
                <Box
                    sx={{
                        padding: '10px 24px',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '0 16px',
                        boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        color="secondary"
                        sx={btnStyles}
                    >
                        Fermer
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        color="secondary"
                        sx={btnStyles}
                    >
                        Voir les résultats
                    </Button>
                </Box>
            </Box>
        </Popover>
    );
};

export default FlexyFilterExpandable;
