import { checkIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { Box, PaletteColor, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import './StepperStep.css';

export type StepperStepExtendedProps = {
    name: string;
    label: string;
    index: number;
    done: boolean;
    disabled: boolean;
    layout?: 'horizontal' | 'vertical';
    onClick: (name: string) => void;
    color?: PaletteColor;
};

const StepperStep: FunctionComponent<StepperStepExtendedProps> = function (
    props: StepperStepExtendedProps,
) {
    const theme = useTheme();
    const { name, label, index, done, disabled, layout = 'horizontal', onClick, color } = props;
    const icon = <FaOptionIcon {...checkIcon.props} />;
    const classNames = ['stepper-step', `stepper-step-${layout}`];
    const mainColor = color || theme.palette.primary;

    if (done) {
        classNames.push('stepper-step-done');
    }

    if (disabled) {
        classNames.push('stepper-step-disabled');
    }

    return (
        <Box
            data-testid="stepper-step"
            className={classNames.join(' ')}
            onClick={() => {
                if (!disabled) {
                    onClick(name);
                }
            }}
        >
            <Box
                className="stepper-step-icon"
                sx={{
                    backgroundColor: done ? mainColor.main : mainColor.light,
                    color: done ? theme.palette.background.dark : mainColor.main,
                }}
            >
                {done ? icon : index}
            </Box>
            <Box
                className="stepper-step-label"
                sx={{ color: theme.palette.text.primary }}
            >
                {label}
            </Box>
        </Box>
    );
};

export default StepperStep;
