import React, { useEffect, useState } from 'react';

import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';

import { useDispatch, useSelector } from 'react-redux';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import {
    FormulaireAppDispatch,
    FormulaireFormalite,
    formulairesFormaliteSelector,
    useFormulaireService,
} from '@europrocurement/l2d-domain';
import { Switch } from '@europrocurement/flexy-components';
import JsonForm from '@europrocurement/l2d-modules/modules/JsonForms/components/JsonForm';
import { jsonForm } from '@europrocurement/flexy-form/utils/types/formTypes';

import { CustomOptionResolvers } from '@europrocurement/flexy-form';
import { toISOString } from '@europrocurement/l2d-utils';

import { useListeSelectionDataResolver } from '@europrocurement/l2d-modules/modules/JsonForms/hooks/useListeSelectionDataResolver';

const PanelFormulaires = function () {
    const formulaireDatasource: DataSource<FormulaireFormalite> = useSelector(
        formulairesFormaliteSelector,
    );

    const { getFormulaireFormalites } = useFormulaireService();

    const dispatch = useDispatch<FormulaireAppDispatch>();

    React.useEffect(() => {
        if (formulaireDatasource.status === 'idle') {
            dispatch(getFormulaireFormalites({}));
        }
    });

    const form = formulaireDatasource.data[1];
    console.log(formulaireDatasource.data);
    // const formDirigeant = formulaireDatasource.data.filter(
    //     (item) => item.libelle === 'Dirigeant',
    // )[0];
    // console.log(formDirigeant);
    let formjson;
    if (form) {
        const formjsonstring = form.formulaire;
        // if (formDirigeant) {
        //     const formjsonstring = formDirigeant.formulaire;
        if (formjsonstring) {
            formjson = JSON.parse(formjsonstring);
        }
    }

    const { setSidebarOpen } = useFullLayout();

    useEffect(() => {
        setSidebarOpen(false);
        return () => {
            setSidebarOpen(true);
        };
    }, [setSidebarOpen]);

    const [debug, setDebug] = useState(false);

    // tu peux jouer avec
    const startPage = 0;
    const defaultValues = {
        denom: 'Rozuel SAS',
    };

    console.log(form);

    const listeSelectionDataResolver = useListeSelectionDataResolver(form);

    const customOptionResolvers: CustomOptionResolvers = {
        listeSelectionDataResolver,
    };

    return (
        <>
            <Switch
                checked={debug}
                onChange={(e, checked) => {
                    setDebug(checked);
                }}
            />
            {formjson ? (
                <JsonForm
                    onChange={(curr, pagelenth, data) => {
                        console.log(curr, pagelenth, JSON.stringify(data));
                        if (data.date_pv) {
                            console.log(toISOString(data.date_pv as unknown as Date));
                        }
                    }}
                    debugMode={debug}
                    json={formjson as jsonForm}
                    startPage={startPage}
                    defaultValues={defaultValues}
                    customOptionResolvers={customOptionResolvers}
                />
            ) : (
                <h1>Aucun formulaire</h1>
            )}
        </>
    );
};

export default PanelFormulaires;
