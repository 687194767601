import React from 'react';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';

type PeoplePreviewProps = {
    firstName?: string;
    lastName?: string;
};

const PeoplePreview: React.FunctionComponent<PeoplePreviewProps> = function (props) {
    const { firstName, lastName } = props;
    const theme = useTheme();
    const { palette } = theme;

    if (!firstName && !lastName) {
        return null;
    }

    const boxStyle = {
        borderRadius: '16px',
        width: '32px',
        height: '32px',
        backgroundColor: palette.primary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <Tooltip title={`${firstName}${lastName}`}>
            <Box sx={boxStyle}>
                <Typography
                    component="span"
                    sx={{
                        fontSize: '13px',
                        fontWeight: 700,
                        color: palette.primary.contrastText,
                    }}
                >
                    {`${firstName?.substring(0, 1).toUpperCase()}${lastName?.substring(0, 1).toUpperCase()}`}
                </Typography>
            </Box>
        </Tooltip>
    );
};

export default PeoplePreview;
