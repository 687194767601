import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
    filtersToElasticQuery,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters,
    Configuration,
    SousClientsApi,
    TiersTiersJsonldTiersRead,
} from '../../../openApi/ApiTiers';
import { SOUS_CLIENT_SLICE_NAME, TIERS_REDUCER_NAME } from '../constants';

export type SousClientApi = TiersTiersJsonldTiersRead & Record<string, unknown>;

export type SousClient = SousClientApi;

const mapperSousClient = function (sousClient: SousClientApi) {
    return {
        ...sousClient,
    } as SousClient;
};

export function createSousClientSlice(configuration: ConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new SousClientsApi(conf);

    const fetchSousClientsCollection: FetchCollectionData<SousClientApi> = ({
        filters,
        pagination,
        orders,
        xIdSociete,
        options,
    }) =>
        api
            .getListTiersTiersCollection(
                {
                    xIdSociete,
                    page: pagination.page + 1,
                    itemsPerPage: pagination.itemsPerPage,
                    search: filtersToElasticQuery(
                        {
                            isSousclient: true,
                            idPrescripteur: filters?.prescripteurId,
                        },
                        orders,
                    ),
                },
                options,
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<SousClientApi>>;

    const fetchSousClientsItem: FetchItemDataType<SousClientApi> = ({
        idItem,
        xIdSociete,
        options,
    }) =>
        api
            .getTiersTiersItem(
                {
                    id: `${idItem}`,
                    xIdSociete,
                },
                options,
            )
            .then((res) => res.data) as Promise<SousClientApi>;

    const { slice: sousClientSlice, dataSourcesThunks: sousClientDataSourcesThunks } =
        SliceFactory.createSlice<SousClientApi, SousClient>(
            SOUS_CLIENT_SLICE_NAME,
            TIERS_REDUCER_NAME,
            ['export'],
            fetchSousClientsCollection,
            fetchSousClientsItem,
            mapperSousClient,
            {
                isSousclient: true,
                demo: false,
                deleted: false,
            },
            {},
            [],
        );

    return { sousClientSlice, sousClientDataSourcesThunks };
}
