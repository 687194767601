export const parcoursFormaliteBasePath = 'newformality';
export const parcoursFormaliteFormaliteStepPath = 'formalite';
export const parcoursFormaliteClientsStepPath = 'clients';
export const parcoursFormalitePremiumDocsStepPath = 'premimumdocs';
export const parcoursFormaliteFormStepPath = 'form';
export const parcoursFormaliteDocsStepPath = 'docs';
export const parcoursFormaliteRecapStepPath = 'recap';
export const parcoursFormaliteDocsFirstStepPath = 'docFirst';

export type ParcoursFormaliteSupportedView =
    | 'stepFormalite'
    | 'stepClient'
    | 'stepPremiumDoc'
    | 'stepForm'
    | 'stepDocs'
    | 'stepRecap';
