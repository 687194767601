import React from 'react';
import RecapFormalityShow from '@europrocurement/l2d-modules/modules/ParcoursFormalite/components/RecapFormalityShow';
import { Box, Container } from '@mui/system';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { Stepper } from '@europrocurement/flexy-components';
import { useParcoursFormalite } from '../provider';

const StepRecap: React.FunctionComponent = function () {
    const { getCurrentDossier } = useParcoursFormalite();

    const { setCustomHeader } = useFullLayout();

    React.useEffect(() => {
        setCustomHeader(
            <Box sx={{ width: '100%', padding: 'auto' }}>
                <Container maxWidth="md">
                    <Stepper
                        steps={[
                            {
                                name: 'step_company',
                                text: 'Société',
                                progress: 100,
                            },
                            {
                                name: 'step_formality',
                                text: 'Formalité',
                                progress: 100,
                            },
                            {
                                name: 'step_documents',
                                text: 'Documents',
                                progress: 100,
                            },
                            {
                                name: 'step_summary',
                                text: 'Récapitulatif',
                                progress: 15,
                            },
                        ]}
                    />
                </Container>
            </Box>,
        );
        return () => {
            setCustomHeader(null);
        };
    }, [setCustomHeader]);

    const dossier = getCurrentDossier();
    if (!dossier) {
        return null;
    }

    return (
        <Container maxWidth="lg">
            <RecapFormalityShow dossier={dossier} />;
        </Container>
    );
};

export default StepRecap;
