import React from 'react';
import { Outlet } from 'react-router-dom';
import { DossierProvider } from './provider';

const DossiersRoot: React.FunctionComponent = function () {
    return (
        <DossierProvider>
            <Outlet />
        </DossierProvider>
    );
};

export default DossiersRoot;
