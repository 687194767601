import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, TextField, useTheme } from '@mui/material';
import useSirenSearch, { CompanyType } from '../../hooks/useSirenSearch';

export type SirenAutocompleteProps = {
    placeholder: string;
    onChange?: (siren: string) => void;
    onSelectItem: (company: CompanyType) => void;
    hasError?: boolean;
};

const SirenAutocomplete: React.FunctionComponent<SirenAutocompleteProps> = function (props) {
    const { placeholder, onChange, onSelectItem, hasError = false } = props;
    const [value, setValue] = useState<CompanyType | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<Array<CompanyType>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { fetchSuggestions } = useSirenSearch();
    const theme = useTheme();
    const { palette } = theme;

    useEffect(() => {
        const refreshSuggestions = async () => {
            fetchSuggestions(inputValue, (suggestions: Array<CompanyType>) => {
                setOptions(suggestions || []);
                setLoading(false);
            });
        };

        if (!/\b\d{9}\b/.test(inputValue)) {
            setOptions([]);
            return;
        }

        setLoading(true);
        refreshSuggestions();
    }, [value, inputValue, fetchSuggestions]);

    return (
        <Autocomplete
            freeSolo
            getOptionLabel={(option: CompanyType) => option.siren}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            loading={loading}
            clearIcon={null}
            loadingText="Chargement..."
            noOptionsText="Aucun résultat pour votre recherche"
            onChange={(event: React.FormEvent<HTMLInputElement>, newValue: CompanyType | null) => {
                if (newValue) {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                } else {
                    setOptions([]);
                    setValue(null);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                if (onChange) {
                    onChange(newInputValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    error={hasError}
                    {...params}
                    placeholder={placeholder}
                    fullWidth
                />
            )}
            renderOption={(childProps, option) => {
                const { key, ...optionProps } = childProps;
                const label = option.description;

                return (
                    <li
                        key={key}
                        {...optionProps}
                    >
                        <Button
                            sx={{ textAlign: 'left', color: palette.text.primary }}
                            onClick={() => onSelectItem(option)}
                        >
                            {label}
                        </Button>
                    </li>
                );
            }}
        />
    );
};

export default SirenAutocomplete;
