import { useContext } from 'react';
import {} from '@europrocurement/l2d-domain/openApi/ApiTiers';

import { getDataThunkType, getItemThunkType } from '@europrocurement/l2d-redux-utils';
import {
    UtilisateurApiObject,
    UtilisateursReducerType,
} from '@europrocurement/l2d-domain/reducers/utilisateurs';

import { UtilisateursApi } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import L2DApiContext from '../L2DApiContext';

export const useUtilisateurService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.utilisateur) {
        throw new Error('Utilisateurs service is not registred in L2DApiProvider');
    }

    const { apis, slices, thunks, fetchItems, getItems, reducer } = context.utilisateur;

    return {
        reducer: reducer as UtilisateursReducerType,

        utilisateurApi: apis.utilisateursApi as UtilisateursApi,

        utilisateurSlice: slices.utilisateurSlice,

        utilisateurDataSourcesThunks: thunks.utilisateurDataSourcesThunks,

        getUtilisateurs: fetchItems.getUtilisateurs as getDataThunkType<UtilisateurApiObject>,

        selectUtilisateurs: getItems.selectUtilisateurs as getItemThunkType<UtilisateurApiObject>,
    };
};
