import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import {
    selectedPrescripteursSelector,
    SousClient,
    SousClientsSelector,
    useTiersService,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import { ChoiceItemProps, ChoiceList } from '@europrocurement/flexy-components';
import { AdresseJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import AddPrescriberClientButton from '../../PrescriberClient/components/AddPrescriberClient/AddPrescriberClientButton';

export type SelectSocieteProps = {
    onSelect?: (ids: number[]) => void;
    multiple?: boolean;
};

const addressToString = (address: AdresseJsonldTiersRead) => {
    let addresseFact = '';
    if (address.adresseApi !== null && address.adresseApi !== '') {
        addresseFact = address.adresseApi ? address.adresseApi : '';
    } else {
        addresseFact = [
            address.adresse1,
            address.adresse2,
            address.adresse3,
            address.adresse4,
            address.codePostal,
            address.ville,
        ]
            .filter(Boolean)
            .join(' ');
    }
    return addresseFact;
};
const selectAddressFacturante = (addresses: AdresseJsonldTiersRead[]) =>
    addresses.filter((adresse) => adresse.facturation === true);

const SelectSociete: React.FunctionComponent<SelectSocieteProps> = function ({
    onSelect,
    multiple = false,
}) {
    const datasource = useTiersServiceSelector(SousClientsSelector).main;
    const dispatch = useDispatch();
    const [sousClients, setSousClients] = useState<Array<SousClient>>([]);
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);
    const [listChoiceSC, setListChoiceSC] = useState<Array<ChoiceItemProps>>([]);
    const [listFiltered, setListFiltered] = useState<Array<ChoiceItemProps>>([]);
    const [listPrescripteur, setListPrescripteur] = useState<Array<ChoiceItemProps>>([]);
    const [societeSelected, setSocieteSelected] = useState<Array<string>>([]);

    const { getSousClients } = useTiersService();

    if (!prescripteur || prescripteur.id === undefined) {
        throw new Error('no prescriber');
    }

    useEffect(() => {
        if (datasource.status === 'succeeded') {
            setSousClients(datasource.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasource.status]);

    useEffect(() => {
        if (!prescripteur || !prescripteur.id) {
            return;
        }
        dispatch({
            type: 'sousClient/setmainPagination',
            payload: {
                itemsPerPage: 500,
                page: 1,
            },
        });
        dispatch({
            type: 'sousClient/setmainFilter',
            payload: {
                key: 'prescripteurId',
                value: prescripteur.id,
            },
        });
        dispatch(getSousClients({}) as unknown as AnyAction);
        const prescripteurPrincipal: ChoiceItemProps = {
            label: prescripteur.raisonSociale,
            value: prescripteur.id?.toString(),
            siren: prescripteur.siren,
        };
        const address = selectAddressFacturante(prescripteur.adresses);
        if (address.length > 0) {
            Object.assign(prescripteurPrincipal, { description: addressToString(address[0]) });
        }
        setListPrescripteur([prescripteurPrincipal]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (datasource.status === 'succeeded') {
            const listSC: ChoiceItemProps[] = [];
            sousClients.forEach((sclient) => {
                if (sclient.id === undefined) {
                    return;
                }
                const obj = {
                    label: sclient.raisonSociale,
                    value: sclient.id.toString(),
                };
                if (sclient.siren !== '') {
                    Object.assign(obj, { siren: sclient.siren });
                }
                const address = selectAddressFacturante(sclient.adresses);
                if (address.length > 0) {
                    Object.assign(obj, { description: addressToString(address[0]) });
                }

                listSC.push(obj);
            });
            setListChoiceSC(listSC);
            setListFiltered(listSC);
        }
    }, [datasource.status, sousClients]);

    const onSubmitWrapper = (data) => {
        const arrayIds = data.idsSociete;
        if (onSelect) {
            onSelect(arrayIds.map((str: string) => Number(str)));
        }
    };

    const onSearchWrapper = (currentSearchString: string) => {
        if (currentSearchString !== '') {
            const result = listChoiceSC.filter(
                (item: ChoiceItemProps) =>
                    item.label.toLowerCase().includes(currentSearchString) ||
                    item.siren?.toLowerCase().includes(currentSearchString),
            );
            setListFiltered(result);
        } else {
            setListFiltered(listChoiceSC);
        }
    };

    const onChangeWrapper = (arraySelected: string[]) => {
        setSocieteSelected(arraySelected);
    };

    return (
        <ChoiceList
            title="Pour quelle(s) société(s) est destinée la commande ?"
            description="Sélectionnez plusieurs structures pour créer un dossier Multi-structures avec des données de formalité identiques et une facturation unique."
            submitButtonLabel="Continuer"
            placeholderLabelSearch="Nom ou Siren de la société concernée"
            listChoice={[
                {
                    label: 'Ma société',
                    listChoice: listPrescripteur,
                    multiple: false,
                },
                {
                    label: 'Mes clients',
                    listChoice: listFiltered,
                    multiple: false,
                },
            ]}
            multiple={multiple}
            onSubmit={onSubmitWrapper}
            search={onSearchWrapper}
            defaultValues={societeSelected}
            onChange={onChangeWrapper}
            componentAdd={
                <AddPrescriberClientButton
                    onCancel={() => {
                        console.log('onCancel');
                    }}
                    onSuccess={(sousClient) => {
                        console.log('onSuccess', sousClient);
                        // attente 1 seconde pour récupération du sous-client
                        setTimeout(() => {
                            dispatch({
                                type: 'sousClient/setmainPagination',
                                payload: {
                                    itemsPerPage: 500,
                                    page: 1,
                                },
                            });
                            dispatch({
                                type: 'sousClient/setmainFilter',
                                payload: {
                                    key: 'prescripteurId',
                                    value: prescripteur.id,
                                },
                            });
                            dispatch(getSousClients({}) as unknown as AnyAction);
                        }, 1000);
                    }}
                />
            }
        />
    );
};

export default SelectSociete;
