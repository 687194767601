import React, { FunctionComponent, useState } from 'react';
import { Box, Button, FormControlLabel, ListItem, useTheme } from '@mui/material';
import { Checkbox } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
// eslint-disable-next-line import/no-cycle
import FlexySelectMultipleItems from './FlexySelectMultipleItems';

export type FlexySelectItem = {
    label: string;
    value: string | number;
    checked: boolean;
    children: FlexySelectItem[];
};

export type FlexySelectItemProps = {
    item: FlexySelectItem;
    nested?: boolean;
    onChange: (value: Array<string | number>) => void;
};

const FlexySelectMultipleItem: FunctionComponent<FlexySelectItemProps> = function (props) {
    const { item, nested = false, onChange } = props;
    const [hover, setHover] = useState<boolean>(false);
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const theme = useTheme();
    const { palette } = theme;

    const isItemChecked = () => {
        if (item.checked) {
            return true;
        }

        let allChildrenChecked = item.children.length > 0;
        item.children.forEach((child) => {
            if (!child.checked) {
                allChildrenChecked = false;
            }
        });

        return allChildrenChecked;
    };

    const isItemIndeterminate = () => {
        if (item.checked || item.children.length <= 0) {
            return false;
        }

        let hasChildrenChecked = false;
        let hasChildrenUnchecked = false;
        item.children.forEach((child) => {
            if (child.checked) {
                hasChildrenChecked = true;
            }

            if (!child.checked) {
                hasChildrenUnchecked = true;
            }
        });

        return hasChildrenChecked && hasChildrenUnchecked;
    };

    const handleChange = () => {
        if (isItemIndeterminate()) {
            const allValues = item.children
                .filter((child) => !child.checked)
                .map((child) => child.value);

            onChange(allValues);

            return;
        }

        if (item.children.length > 0) {
            onChange(item.children.map((child) => child.value));

            return;
        }

        onChange([item.value]);
    };

    let bgColor = hover ? palette.background.default : '';

    if (isItemChecked() || isItemIndeterminate()) {
        bgColor = palette.primary.light;
    }

    return (
        <ListItem
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0',
            }}
            key={`${item.label}-${item.value}`}
        >
            <Box
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={{
                    backgroundColor: bgColor,
                    width: '100%',
                    height: '44px',
                    margin: '0 0 3px 0',
                    borderRadius: '12px',
                    padding: '0 16px 0 14px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <FormControlLabel
                    sx={{
                        margin: '0 0 3px 0',
                        width: '100%',
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                        fontSize: '15px',
                        fontWeight: '500',
                        lineHeight: '24px',
                        paddingLeft: nested ? '30px' : '0',
                    }}
                    disableTypography
                    control={
                        <Checkbox
                            checked={isItemChecked()}
                            indeterminate={isItemIndeterminate()}
                            onChange={() => handleChange()}
                            sx={{ padding: 0, lineHeight: 0 }}
                        />
                    }
                    label={item.label}
                />
                {item.children.length > 0 && (
                    <Button
                        sx={{
                            color: palette.primary.dark,
                            width: 'auto',
                            minwidth: 'auto',
                            fontSize: '12px',
                            fontWeight: '900',
                            lineHeight: '12px',
                        }}
                        onClick={() => setCollapsed((v) => !v)}
                    >
                        <FaOptionIcon icon={collapsed ? faChevronUp : faChevronDown} />
                    </Button>
                )}
            </Box>
            {!collapsed && item.children.length > 0 && (
                <FlexySelectMultipleItems
                    items={item.children}
                    onChange={onChange}
                    nested
                />
            )}
        </ListItem>
    );
};

export default FlexySelectMultipleItem;
