import { Previous } from '@europrocurement/flexy-components';
import {
    CustomOptionResolvers,
    FlexyForm,
    jsonFormToFormStructure,
} from '@europrocurement/flexy-form';
import { jsonForm, visibilityType } from '@europrocurement/flexy-form/utils/types/formTypes';
import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { darkStyles, JsonView } from 'react-json-view-lite';

/**
 * Prends en argument un vrai json de l'api
 * données de base ( a mettre dans un useState, et changer l'etat a chaque page )
 *
 * gerrer un mode debug
 * qui affiche le json de la données dans un JsonView
 *
 * exposer son etat
 *  - page courante
 *  - nombre de page
 *  - etat saisie
 *
 * onChange( {
 *      currentPage
 *      pageLength
 *      data
 * } )
 * RG : a chaque changement de page
 *  ou changement de structure
 *  appel onChange avec les 3 objets
 *
 *
 * gerrer un state pour la data
 *
 * mettre la structure de la page courante dans un useMemo
 *
 *
 *
 *
 *
 */

export const visibilityTranslation: Record<visibilityType, string> = {
    FULL: 'Complète',
    PARTIAL: 'Partielle',
};

export type JsonFormProps = {
    json: jsonForm;
    debugMode: boolean;
    // mécanique de champs a cacher ou pas (premium/ ou pas)
    visibility?: visibilityType;
    // reprise a partir d'une page spécifique
    startPage: number;
    // reprise avec une partie des données saisies
    defaultValues: Record<string, unknown>;
    customOptionResolvers?: CustomOptionResolvers;
    onChange?: (currentPage: number, pageLength: number, data?: Record<string, unknown>) => void;
};

const JsonForm: React.FunctionComponent<JsonFormProps> = function ({
    json,
    debugMode,
    onChange,
    startPage = 0,
    defaultValues = {},
    customOptionResolvers = {},
    visibility = 'FULL',
}: JsonFormProps) {
    const [currentPage, setCurrentPage] = useState(startPage);

    const theme = useTheme();

    const [data, setData] = useState({ ...defaultValues });

    /**
     * a chaque submit il faut sauvegarder les données dans data
     */
    const formContext = useForm({
        mode: 'onTouched',
        defaultValues,
    });

    const copyJson = function (jsonToCopy: jsonForm) {
        return { ...jsonToCopy };
    };

    const jsonFiltered = React.useMemo(() => copyJson(json), [json]);
    jsonFiltered.pages = jsonFiltered.pages.filter((page) =>
        page.visibility ? page.visibility.includes(visibility) : true,
    );

    const onPrevious = () => {
        setCurrentPage(currentPage - 1);
        // appeler le on change
        if (onChange) {
            onChange(currentPage - 1, jsonFiltered.pages.length);
        }
    };

    const onSubmitPage = (submitedData: Record<string, unknown>) => {
        const newData = { ...data, ...submitedData };
        // sauvzgarder la donnée dans le state data
        setData(newData);
        // passer a la page suivante
        if (currentPage < jsonFiltered.pages.length - 1) {
            setCurrentPage(currentPage + 1);
            // appeler le on change
            if (onChange) {
                onChange(currentPage + 1, jsonFiltered.pages.length, newData);
            }
        } else if (onChange) {
            // passage étape suivante hors formulaire
            onChange(currentPage + 1, jsonFiltered.pages.length, newData);
        }
    };

    const structure = React.useMemo(
        () => jsonFormToFormStructure(jsonFiltered, currentPage, visibility),
        [jsonFiltered, currentPage, visibility],
    );

    return (
        <>
            {debugMode ? (
                <div>
                    <JsonView
                        data={data}
                        shouldExpandNode={(level) => level > 2}
                        style={darkStyles}
                    />
                    page {currentPage + 1} sur {jsonFiltered.pages.length}
                </div>
            ) : null}

            {currentPage !== 0 ? <Previous onClick={onPrevious} /> : null}
            <FlexyForm
                formContext={formContext}
                formStructure={structure}
                formObject={defaultValues}
                onSubmit={onSubmitPage}
                customOptionResolvers={customOptionResolvers}
                submitButton={{
                    label: 'Continuer',
                    style: {
                        backgroundColor: theme.palette.primary.dark,
                        width: '100%',
                        marginTop: '30px',
                        padding: '10px',
                        fontSize: '1rem',
                        borderRadius: '15px',
                    },
                }}
            />
        </>
    );
};

export default JsonForm;
