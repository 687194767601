import { CatalogApiObject, Prescripteur } from '@europrocurement/l2d-domain';
import {
    CollectionDeDossierJsonldCollectiondossierRead,
    DossierJsonldDossierRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { Breakpoint } from '@mui/material';
import { createContext } from 'react';

export type ParcoursFormaliteContextType = {
    // data
    prescripteur: Prescripteur;
    prescripteurId: number;
    currentCollectionId: number;
    currentDossierId: number;
    currentCatalog?: CatalogApiObject;
    currentCatalogs?: CatalogApiObject[];
    collections: {
        [idSousClient: number]: CollectionDeDossierJsonldCollectiondossierRead;
    };
    dossiers: {
        [idSousClient: number]: DossierJsonldDossierRead;
    };
    idFormalites: Array<number>;
    idSousClients: Array<number>;
    // manipulation
    getPrescripteur: () => Prescripteur;
    getCurrentCollection: () => CollectionDeDossierJsonldCollectiondossierRead | null;
    getCurrentDossier: () => DossierJsonldDossierRead | null;
    getCollections: () => {
        [idSousClient: number]: CollectionDeDossierJsonldCollectiondossierRead;
    };
    setCollections: (collections: {
        [idSousClient: number]: CollectionDeDossierJsonldCollectiondossierRead;
    }) => void;

    // Checks
    isFormaliteSimple: () => boolean;
    isFormaliteCombinee: () => boolean;
    isFormaliteMultiple: () => boolean;
    isFormaliteDupliquee: () => boolean;
    setIdFormalites: (ids: Array<number>) => void;
    setIdSousClients: (ids: Array<number>) => void;
    setCurrentCatalog: (catalog: CatalogApiObject) => void;
    setCurrentCatalogs: (catalogs: CatalogApiObject[]) => void;
    save: () => void;
    create: (newIdSousClients: Array<number>, newIdFormalite: Array<number>) => void;
    containerWidth: Breakpoint;
};

const fakePrescripteur = {
    idPrescripteur: -1,
    raisonSociale: 'Temp',
    adresses: [],
    contacts: [],
    personnePhysique: false,
    origine: {},
    relationsSocietes: [],
};

export const ParcoursFormaliteContext = createContext<ParcoursFormaliteContextType>({
    prescripteur: fakePrescripteur,
    collections: {},
    dossiers: {},
    currentCollectionId: -1,
    currentDossierId: -1,
    prescripteurId: -1,
    idFormalites: [],
    idSousClients: [],
    currentCatalogs: [],
    getPrescripteur: () => fakePrescripteur,
    getCurrentCollection: () => null,
    getCurrentDossier: () => null,
    getCollections: () => [],
    isFormaliteSimple: () => false,
    isFormaliteCombinee: () => false,
    isFormaliteMultiple: () => false,
    isFormaliteDupliquee: () => false,
    setCollections: () => null,
    setIdFormalites: () => null,
    setIdSousClients: () => null,
    setCurrentCatalog: () => null,
    setCurrentCatalogs: () => null,
    save: () => null,
    create: () => null,
    containerWidth: 'sm',
});
