import { FormStructure } from '@europrocurement/flexy-form/components';
import { jsonCustom } from '../types/formTypes';

const jsonCustomToFormElement: (input: jsonCustom) => FormStructure = (input) => {
    const baseField: FormStructure = {
        type: input.type,
        name: input.name,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
    };

    return baseField;
};

export default jsonCustomToFormElement;
