import { AxiosRequestConfig } from 'axios';
import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    ActionReducerMapBuilder,
    AsyncThunk,
    Action,
} from '@reduxjs/toolkit';

import { RootStateOrAny } from 'react-redux';

import {
    europrocurementApiFormatData,
    europrocurementApiFormatSelected,
    initialDataSource,
} from './EuroprocurementApiUtils';

import {
    ApiCollectionResponse,
    EuroprocStore,
    Pagination,
    Order,
    DataSource,
    Search,
    PreFilter,
} from './EuroprocurementApiType';

export type FetchCollectionInputType = {
    search: string;
    pagination: Pagination;
    filters: Record<string, unknown>;
    orders: Order[];
    xIdSociete: number;
    rootstate: RootStateOrAny;
    options?: AxiosRequestConfig;
};

export type FetchItemInputType = {
    idItem: number | string;
    search: string;
    pagination: Pagination;
    filters: Record<string, unknown>;
    orders: Order[];
    xIdSociete: number;
    rootstate: RootStateOrAny;
    options?: AxiosRequestConfig;
};

export type getDataThunkType<T> = AsyncThunk<
    ApiCollectionResponse<T>,
    { options?: AxiosRequestConfig },
    Record<string, never>
>;

export type getItemThunkType<T> = AsyncThunk<
    T,
    { id: string | number; options?: AxiosRequestConfig },
    Record<string, never>
>;

export type DataSourcesThunksType<T> = {
    [x: string]: {
        getData: getDataThunkType<T>;
        getSelected: getItemThunkType<T>;
        deleteSelected?: Action;
        data: {
            [x: string]: getDataThunkType<T>;
        };
        item: {
            [x: string]: getItemThunkType<T>;
        };
    };
};

export type FetchCollectionData<T> = ({
    search,
    pagination,
    filters,
    orders,
    xIdSociete,
    rootstate,
    options,
}: FetchCollectionInputType) => Promise<ApiCollectionResponse<T>>;

export type FetchItemDataType<T> = ({
    idItem,
    search,
    pagination,
    filters,
    orders,
    xIdSociete,
    rootstate,
    options,
}: FetchItemInputType) => Promise<T>;
export class SliceFactory {
    static debug = false;

    static createInitialState = <U>(
        slicename: string,
        aditionalDataSourcesNames: string[],
        defaultFilters?: Record<string, unknown>,
        defaultOrders?: Order[],
    ): EuroprocStore<U> =>
        aditionalDataSourcesNames.reduce(
            (prev, datasourceName) => ({
                ...prev,
                [datasourceName]: {
                    ...initialDataSource,
                    filters: { ...defaultFilters } || {},
                    preFilter: {},
                    orders: defaultOrders || [],
                    name: datasourceName,
                    slicename,
                } as DataSource<U>,
            }),
            {
                main: {
                    ...initialDataSource,
                    filters: { ...defaultFilters } || {},
                    preFilter: {},
                    orders: defaultOrders || [],
                    slicename,
                } as DataSource<U>,
            },
        );

    /**
     * @param slicename Le nom du Slice
     * @param reducername Le nom du reducer, qu'on utilise pour s'abonner aux données
     * @param aditionalDataSourcesNames C'est un tableau de *string* qui génère automatiquement des sous-objet (des Datasources) dans le reducers
     * @param fetchData La fonction qui permet d'aller chercher les datas
     * @param fetchItem La fonction pour aller chercher un item en particulier
     * @param mapper Fonction dont le rôle et de transformer le type d'objet renvoyer par l'API en type utilisable par nos frontends (DTO)
     * @returns Slice et Objet contenant des sous-objet getData & getSelected
     */
    static createSlice<T, U>(
        slicename: string,
        reducername: string,
        aditionalDataSourcesNames: string[],
        fetchData: FetchCollectionData<T>,
        fetchItem: FetchItemDataType<T>,
        mapper: (item: T) => U,
        defaultFilters?: Record<string, unknown>,
        additionalThunks?: {
            data?: {
                [x: string]: FetchCollectionData<T>;
            };
            item?: {
                [x: string]: FetchItemDataType<T>;
            };
        },
        defaultOrders?: Order[],
    ) {
        const initialState: EuroprocStore<U> = this.createInitialState<U>(
            slicename,
            aditionalDataSourcesNames,
            defaultFilters,
            defaultOrders,
        );

        const datasourceNames = Object.keys(initialState);

        const dataSourcesThunks = datasourceNames.reduce(
            (prev: DataSourcesThunksType<T>, datasourceName: string) => {
                const res: DataSourcesThunksType<T> = {
                    ...prev,
                    [datasourceName]: {
                        getData: this.createCollectionAsyncThunk<T>(
                            reducername,
                            slicename,
                            fetchData,
                            datasourceName,
                        ),
                        getSelected: this.createItemAsyncThunk<T>(
                            reducername,
                            slicename,
                            fetchItem,
                            datasourceName,
                        ),
                        data: {},
                        item: {},
                    },
                };

                if (additionalThunks && additionalThunks.data) {
                    Object.keys(additionalThunks.data).forEach((key) => {
                        if (additionalThunks.data) {
                            const thunk = additionalThunks.data[key];

                            res[datasourceName].data[key] = this.createCollectionAsyncThunk<T>(
                                reducername,
                                slicename,
                                thunk,
                                datasourceName,
                            );
                        }
                    });
                }

                return res;
            },
            {},
        );

        const slice = createSlice({
            name: slicename,
            initialState,
            reducers: datasourceNames.reduce(
                (prev, datasourceName) => ({
                    ...prev,
                    [`set${datasourceName}Pagination`](
                        state: EuroprocStore<U>,
                        { payload: { itemsPerPage, page } }: PayloadAction<Pagination>,
                    ) {
                        const newState = state;
                        newState[datasourceName].pagination.itemsPerPage = itemsPerPage;
                        newState[datasourceName].pagination.page = page;

                        return newState;
                    },
                    [`reset${datasourceName}Pagination`](state: EuroprocStore<U>) {
                        const newState = state;
                        newState[datasourceName].pagination.itemsPerPage = 10;
                        newState[datasourceName].pagination.page = 0;

                        return newState;
                    },
                    [`set${datasourceName}Search`](
                        state: EuroprocStore<U>,
                        { payload: { search } }: PayloadAction<Search>,
                    ) {
                        const newState = state;
                        newState[datasourceName].search = search;

                        return newState;
                    },
                    [`reset${datasourceName}Search`](state: EuroprocStore<U>) {
                        const newState = state;
                        delete newState[datasourceName].search;

                        return newState;
                    },
                    [`reset${datasourceName}Filter`](state: EuroprocStore<U>) {
                        const newState = state;
                        newState[datasourceName].filters = { ...defaultFilters } || {};
                        return newState;
                    },
                    [`set${datasourceName}Filter`](
                        state: EuroprocStore<U>,
                        {
                            payload: { key, value },
                        }: { payload: { key: string; value: string | number | boolean } },
                    ) {
                        const newState = state;
                        newState[datasourceName].pagination.page = 0;

                        if (!newState[datasourceName].filters)
                            newState[datasourceName].filters = {};

                        // Set the value
                        if (value !== '') {
                            if (newState[datasourceName].filters)
                                newState[datasourceName].filters[key] = value;
                        } else delete newState[datasourceName].filters[key];

                        if (
                            newState[datasourceName].filters &&
                            Object.keys(newState[datasourceName].filters).length === 0
                        )
                            newState[datasourceName].filters = {};

                        return newState;
                    },
                    [`set${datasourceName}PreFilter`](
                        state: EuroprocStore<U>,
                        { payload }: PayloadAction<PreFilter>,
                    ) {
                        const newState = state;
                        const currentFilters: Record<string, unknown> = {};

                        newState[datasourceName].preFilter = payload;
                        payload.filters.forEach(({ field, value }) => {
                            currentFilters[field] = value;
                        });
                        newState[datasourceName].filters = currentFilters;

                        return newState;
                    },
                    [`delete${datasourceName}PreFilter`](state: EuroprocStore<U>) {
                        const newState = state;
                        newState[datasourceName].preFilter = undefined;

                        return newState;
                    },
                    [`set${datasourceName}Order`](
                        state: EuroprocStore<U>,
                        { payload: { field, value } }: PayloadAction<Order>,
                    ) {
                        const newState = state;
                        if (value) {
                            newState[datasourceName].orders = [{ field, value }];
                        } else if (newState[datasourceName].orders) {
                            newState[datasourceName].orders = newState[
                                datasourceName
                            ].orders.filter((order) => order.field !== field);
                            if (!newState[datasourceName].orders.length)
                                newState[datasourceName].orders = [];
                        }

                        return newState;
                    },
                    [`reset${datasourceName}Order`](state: EuroprocStore<U>) {
                        const newState = state;
                        newState[datasourceName].orders = defaultOrders ? [...defaultOrders] : [];
                        return newState;
                    },
                    [`delete${datasourceName}Filter`](
                        state: EuroprocStore<U>,
                        { payload: { field } }: unknown,
                    ) {
                        const newState = state;
                        delete newState[datasourceName].filters?.[field];

                        if (
                            newState[datasourceName].filters &&
                            Object.keys(newState[datasourceName].filters).length === 0
                        )
                            newState[datasourceName].filters = {};

                        return newState;
                    },
                    [`delete${datasourceName}Selected`](state: EuroprocStore<U>) {
                        const newState = state;
                        delete newState[datasourceName].selected;
                        newState[datasourceName].selectedStatus = 'idle';

                        return newState;
                    },
                    [`set${datasourceName}Selected`](
                        state: EuroprocStore<U>,
                        { payload }: PayloadAction<U>,
                    ) {
                        const newState = state;
                        newState[datasourceName].selected = payload;

                        return newState;
                    },
                    [`clear${datasourceName}Data`](state: EuroprocStore<U>) {
                        const newState = state;
                        newState[datasourceName].status = 'idle';
                        newState[datasourceName].data = [];

                        return newState;
                    },
                }),
                {},
            ),
            extraReducers: (builder) => {
                datasourceNames.forEach((datasourceName) => {
                    this.buildGetDataExtraReducers<T, U>(
                        builder,
                        dataSourcesThunks[datasourceName].getData,
                        slicename,
                        datasourceName,
                        mapper,
                    );
                    this.buildGetSelectedExtraReducers<T, U>(
                        builder,
                        dataSourcesThunks[datasourceName].getSelected,
                        datasourceName,
                        mapper,
                    );
                });
            },
        });

        return { slice, dataSourcesThunks };
    }

    static createCollectionAsyncThunk<T>(
        reducername: string,
        slicename: string,
        fetchData: FetchCollectionData<T>,
        datasourceName: string,
    ) {
        const asyncThunkName =
            datasourceName !== 'main'
                ? `${slicename}/get${
                      datasourceName.charAt(0).toUpperCase() + datasourceName.slice(1)
                  }Data`
                : `${slicename}/getData`;

        return createAsyncThunk(
            asyncThunkName,
            async ({ options }: { options?: AxiosRequestConfig }, thunkApi) => {
                const rootstate = thunkApi.getState() as RootStateOrAny;

                const { search, pagination, filters, orders } =
                    rootstate[reducername][slicename][datasourceName];

                const { xIdSociete } = rootstate.customizer;

                const fetchDataObject: FetchCollectionInputType = {
                    search,
                    pagination,
                    filters,
                    orders,
                    xIdSociete,
                    rootstate,
                    options,
                };

                const res = await fetchData(fetchDataObject);

                return res;
            },
            {
                condition: (arg, { getState }) => {
                    if (this.debug) {
                        console.debug('condition', asyncThunkName, arg, getState());
                    }
                    try {
                        const state = getState() as Record<string, unknown>;
                        const reducer = state[reducername] as Record<string, unknown>;
                        const slice = reducer[slicename] as Record<string, unknown>;
                        const datasource = slice[datasourceName] as DataSource<unknown>;
                        if (datasource.status === 'loading') {
                            if (this.debug) {
                                console.debug('condition result', false);
                            }
                            return false;
                        }
                    } catch (error) {
                        console.error('error in redux tree', error);
                    }
                    if (this.debug) {
                        console.debug('condition result', true);
                    }
                    return true;
                },
            },
        );
    }

    static createItemAsyncThunk<T>(
        reducername: string,
        slicename: string,
        fetchData: FetchItemDataType<T>,
        datasourceName: string,
    ) {
        const asyncThunkName =
            datasourceName !== 'main'
                ? `${slicename}/get${
                      datasourceName.charAt(0).toUpperCase() + datasourceName.slice(1)
                  }Selected`
                : `${slicename}/getSelected`;

        return createAsyncThunk(
            asyncThunkName,
            async (
                { id, options }: { id: string | number; options?: AxiosRequestConfig },
                thunkApi,
            ) => {
                const rootstate = thunkApi.getState() as RootStateOrAny;

                const { search, pagination, filters, orders } = rootstate[reducername][slicename];

                const { xIdSociete } = rootstate.customizer;

                const fetchDataObject: FetchItemInputType = {
                    idItem: id,
                    search,
                    pagination,
                    filters,
                    orders,
                    xIdSociete,
                    rootstate,
                    options,
                };

                const res = await fetchData(fetchDataObject);

                return res;
            },
            {
                condition: (arg, { getState }) => {
                    if (this.debug) {
                        console.debug('condition', asyncThunkName, arg, getState());
                    }
                    try {
                        const state = getState() as Record<string, unknown>;
                        const reducer = state[reducername] as Record<string, unknown>;
                        const slice = reducer[slicename] as Record<string, unknown>;
                        const datasource = slice[datasourceName] as DataSource<unknown>;
                        if (datasource.selectedStatus === 'loading') {
                            if (this.debug) {
                                console.debug('condition result', false);
                            }
                            return false;
                        }
                    } catch (error) {
                        console.error('error in redux tree', error);
                    }
                    if (this.debug) {
                        console.debug('condition result', true);
                    }
                    return true;
                },
            },
        );
    }

    static buildGetDataExtraReducers<T, U>(
        builder: ActionReducerMapBuilder<EuroprocStore<U>>,
        getData: AsyncThunk<
            ApiCollectionResponse<T>,
            { options?: AxiosRequestConfig },
            Record<string, never>
        >,
        slicename: string,
        datasourceName: string,
        mapper: (item: T) => U,
    ) {
        builder
            .addCase(getData.pending, (state) => {
                const newState = state as EuroprocStore<U>;
                newState[datasourceName].status = 'loading';
                return newState;
            })
            .addCase(getData.rejected, (state) => {
                const newState = state as EuroprocStore<U>;
                newState[datasourceName].status = 'failed';
                newState[datasourceName].error =
                    'Erreur lors de la recupération des données ( extraReducer => getData.rejected)';
                return newState;
            })
            .addCase(getData.fulfilled, (state, action) =>
                europrocurementApiFormatData<T, U>(
                    state as EuroprocStore<U>,
                    action.payload,
                    datasourceName,
                    mapper,
                ),
            );
    }

    static buildGetSelectedExtraReducers<T, U>(
        builder: ActionReducerMapBuilder<EuroprocStore<U>>,
        getSelected: AsyncThunk<
            T,
            { id: string | number; options?: AxiosRequestConfig },
            Record<string, never>
        >,
        datasourceName: string,
        mapper: (item: T) => U,
    ) {
        builder
            .addCase(getSelected.pending, (state) => {
                const newState: EuroprocStore<U> = state as EuroprocStore<U>;
                newState[datasourceName].selectedStatus = 'loading';
                return newState;
            })
            .addCase(getSelected.rejected, (state) => {
                const newState: EuroprocStore<U> = state as EuroprocStore<U>;
                newState[datasourceName] = {
                    ...(initialDataSource as DataSource<U>),
                    selectedStatus: 'failed',
                };
                return newState;
            })
            .addCase(getSelected.fulfilled, (state, action) =>
                europrocurementApiFormatSelected<T, U>(
                    state as EuroprocStore<U>,
                    action,
                    datasourceName,
                    mapper,
                ),
            );
    }
}
