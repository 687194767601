import React, { CSSProperties, FunctionComponent } from 'react';
import { Box, Button, FormControl, Typography } from '@mui/material';
import { lighten, useTheme } from '@mui/system';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import ChoiceItem, { type ChoiceItemProps } from '../../atoms/ChoiceItem';
import Checkbox from '../../atoms/Checkbox';

export type ChoiceItemGroupProps = {
    label: string;
    multiple?: boolean;
    listObjectGroup: ChoiceItemProps[];
    selectedValues: string[];
    onChange?: (value: string) => void;
};

const ChoiceItemObjectGroup: FunctionComponent<ChoiceItemGroupProps> = function ({
    label,
    multiple = true,
    listObjectGroup = [],
    selectedValues = [],
    onChange,
}) {
    let childSelected: boolean = false;
    const theme = useTheme();
    const [hide, setHide] = React.useState(true);
    let objectValues: string[] = [];

    // select/unselect child box if parent box is checked/unchecked onclick
    const handleMultipleChange = () => {
        childSelected = !childSelected;
        if (listObjectGroup.length > 0 && onChange) {
            objectValues = listObjectGroup.map((obj) => obj.value);
            const missingValuesCheckbox = objectValues.filter(
                (str) => !selectedValues.includes(str),
            );
            if (
                missingValuesCheckbox.length === 0 ||
                missingValuesCheckbox.length === listObjectGroup.length
            ) {
                // Si j'ai aucune case ou toutes les cases cochées, j'inverse tout
                listObjectGroup.forEach((obj) => {
                    onChange(obj.value);
                });
            } else {
                // Sinon je décoche celles qui sont cochées
                listObjectGroup.forEach((obj) => {
                    if (obj.value && selectedValues.includes(obj.value)) {
                        onChange(obj.value);
                    }
                });
            }
        }
    };
    const handleSimpleChange = () => {
        if (childSelected) {
            if (selectedValues.length > 0 && selectedValues[0] !== undefined) {
                onChange(selectedValues[0].toString());
            }
        }
    };
    // Check to set properties of checkbox parent
    let missingValues: string[] = [];
    const matches = listObjectGroup.filter((obj) => selectedValues.includes(obj.value));
    if (matches.length > 0) {
        if (!childSelected) {
            childSelected = true;
        }
        objectValues = listObjectGroup.map((obj) => obj.value);
        missingValues = objectValues.filter((str) => !selectedValues.includes(str));
    }

    let cssChecked: CSSProperties = {};

    if (childSelected) {
        cssChecked = {
            borderColor: theme.palette.primary.main,
            background: lighten(theme.palette.primary.main, 0.95),
        };
    } else {
        cssChecked = {
            borderColor: theme.palette.grey[300],
            background: theme.palette.background.paper,
        };
    }

    let cssHide: React.CSSProperties = {};
    let iconeName: string = 'chevron-up';
    if (hide) {
        cssHide = { display: 'none' };
        iconeName = 'chevron-down';
    }

    return (
        <Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    borderRadius: '15px',
                    cursor: 'pointer',
                    alignItems: 'center',
                    minHeight: '61px',
                    marginBottom: '2px',
                    ...cssChecked,
                }}
            >
                <FormControl
                    sx={{
                        marginLeft: '3px',
                    }}
                >
                    <Checkbox
                        name="checkbox-parent"
                        checked={childSelected}
                        indeterminate={missingValues.length > 0 && childSelected}
                        onChange={multiple ? handleMultipleChange : handleSimpleChange}
                    />
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '5px',
                    }}
                >
                    <Button
                        sx={[
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                                paddingLeft: 0,
                                color: theme.palette.primary.text,
                            },
                            {
                                '&:hover': {
                                    ...cssChecked,
                                },
                            },
                        ]}
                        onClick={() => {
                            setHide(!hide);
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '600',
                            }}
                        >
                            {label}
                        </Typography>

                        <FaOptionIcon
                            icon={['fasl', iconeName as IconName]}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: theme.palette.grey[600],
                            }}
                        />
                    </Button>
                </Box>
            </Box>
            {listObjectGroup.map((choiceItem) => (
                <Box
                    sx={{
                        paddingLeft: '30px',
                        ...cssHide,
                    }}
                    key={choiceItem.value}
                >
                    <ChoiceItem
                        key={choiceItem.value}
                        label={choiceItem.label}
                        description={choiceItem.description ? choiceItem.description : ''}
                        value={choiceItem.value}
                        siren={choiceItem.siren ? choiceItem.siren : ''}
                        isChecked={selectedValues.includes(choiceItem.value)}
                        isDisabled={choiceItem.isDisabled ? choiceItem.isDisabled : false}
                        onChange={onChange}
                    />
                </Box>
            ))}
        </Box>
    );
};
export default ChoiceItemObjectGroup;
