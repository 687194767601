import React, { useState } from 'react';
import { Button, useTheme, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
// import './AddPrescriberClientButton.css';
import { TiersSousclientJsonldSousclientCreated } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import AddPrescriberClientModal from './AddPrescriberClientModal';

export type AddPrescriberClientButtonProps = {
    onSuccess: (sousClient: TiersSousclientJsonldSousclientCreated) => void;
    onCancel: () => void;
};

const AddPrescriberClientButton: React.FunctionComponent<AddPrescriberClientButtonProps> =
    function (props) {
        const { onSuccess, onCancel } = props;
        const [open, setOpen] = useState(false);
        const theme = useTheme();

        const handleSuccess = (sousClient: TiersSousclientJsonldSousclientCreated) => {
            setOpen(false);
            onSuccess(sousClient);
        };

        const handleCancel = () => {
            setOpen(false);
            onCancel();
        };

        const handleOpenModal = () => {
            setOpen(true);
        };

        const buttonStyle = {
            display: 'flex',
            alignItems: 'center',
            padding: '8px 14px',
            gap: '12px',
            borderRadius: '12px',
            width: '100%',
            justifyContent: 'flex-start',
            fontFamily: 'Inter',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '24px',
            color: theme.palette.text.primary,
        };

        return (
            <>
                <AddPrescriberClientModal
                    open={open}
                    onSuccess={handleSuccess}
                    onCancel={handleCancel}
                />
                <Button
                    onClick={handleOpenModal}
                    sx={buttonStyle}
                >
                    <Typography
                        variant="l2dbodys"
                        color="text.secondary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '100%',
                            gap: '12px',
                            fontWeight: '600',
                        }}
                    >
                        <FaOptionIcon icon={faPlus} />
                        Ajouter une société
                    </Typography>
                </Button>
            </>
        );
    };

export default AddPrescriberClientButton;
