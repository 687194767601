/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import {
    useResolvePath,
    FlexyInputProps,
    FlexyTextFieldProps,
    FlexySelectProps,
    typeDateList,
    FlexyDateProps,
    SwitchProps,
    FlexyHeaderFormProps,
    FlexyHeaderForm,
    FlexyInput,
    DirigeantWidgetType,
} from '@europrocurement/flexy-components';

import { GridProps } from '@mui/material';
import FlexySeparator, {
    FlexySeparatorFormProps,
} from '@europrocurement/flexy-components/components/molecules/FlexySeparator';
import DirigeantList from '@europrocurement/flexy-components/components/organisms/DirigeantList';
// eslint-disable-next-line import/no-extraneous-dependencies
import { deleteIcon, FaOptionIcon, preview } from '@europrocurement/l2d-icons';
import {
    FormStructure,
    FlexyFormInputProps,
    CustomOptionResolvers,
    FlexySelectFormStructure,
    ControlledSelectItemsFormStructure,
} from '../FlexyForm/FlexyFormTypes';
/* eslint-disable import/no-cycle */
import { FormStructureRenderer, FormStructureRendererProps } from './FormStructureRenderer';
/* eslint-enable import/no-cycle */
import { SubformArray } from './SubformArray';
import { ControlledAutocomplete } from '../AutocompleteStore/ControlledAutocompleteStore';
import ControlledSelectItems, {
    ControlledSelectItemsProps,
} from '../ControlledSelectItems/ControlledSelectItems';
import ControlledTreeItems from '../ControlledTreeItems/ControlledTreeItems';

export type FormInputInformations = {
    base: string;
    name: string;
    path: string;
    objectPath: string;
};

export type FormItemRendererProps = {
    input: FormStructure;
    basePath: string;
    shouldUnregister?: boolean;
    inputsProps?: FlexyFormInputProps;
    gridProps?: GridProps;
    customOptionResolvers?: CustomOptionResolvers;
};

export const FormItemRenderer: React.FunctionComponent<FormItemRendererProps> = function ({
    input,
    basePath,
    shouldUnregister = false,
    inputsProps,
    gridProps,
    customOptionResolvers,
}: FormItemRendererProps) {
    const formContext = useFormContext();
    const { resolvePath } = useResolvePath();

    const { register } = formContext;
    const { formState } = formContext;
    const { control } = formContext;

    const extandedFormContext = {
        ...formContext,
        getValue: (path: string) => resolvePath(path, formContext.getValues()),
    };

    let display = true;
    let disabled = false;

    if (input.display === false) {
        display = false;
    } else if (typeof input.display === 'function') {
        display = input.display(formContext);
    }

    if (display === false) {
        return null;
    }

    if (input.disabled === true) {
        disabled = true;
    } else if (input.disabled) {
        disabled = input.disabled(formContext);
    }

    if (input.type === 'customItem') {
        const { renderField, ...customItemProps } = {
            ...input,
            base: basePath,
            path: `${basePath}${input.name}`,
            objectPath: basePath.replace(/\.$/, ''),
        };
        return renderField(extandedFormContext, customItemProps);
    }

    // Si l'input est de type header on rends un compo FlexyHeaderForm
    if (input.type === 'header') {
        const props = input as FlexyHeaderFormProps;
        return <FlexyHeaderForm {...props} />;
    }

    if (input.type === 'separator') {
        const props = input as FlexySeparatorFormProps;
        return <FlexySeparator {...props} />;
    }

    // Si l'input est de type subform
    if (input.type === 'subform') {
        const props = {
            ...input,
            basePath: `${input.name}.`,
        } as FormStructureRendererProps;
        return (
            <FormStructureRenderer
                {...props}
                inputsProps={inputsProps}
                gridProps={gridProps}
                customOptionResolvers={customOptionResolvers}
            />
        );
    }

    // Si l'input est de type subformarray
    if (input.type === 'subformarray') {
        return (
            <SubformArray
                input={input}
                basePath={`${input.name}`}
                inputsProps={inputsProps}
                gridProps={input.gridProps || gridProps}
                events={input.events}
                displayAddButton={input.displayAddButton}
                displayClearButton={input.displayClearButton}
                customOptionResolvers={customOptionResolvers}
            />
        );
    }

    if (input.type === 'selectItems') {
        const { optionsResolver }: ControlledSelectItemsFormStructure = input;
        const commonProps: {} & ControlledSelectItemsProps = {
            ...input,
        };
        if (optionsResolver) {
            if (!customOptionResolvers) {
                throw new Error(
                    'you cant have optionsResolver in select without customOptionResolvers in form',
                );
            }
            if (!customOptionResolvers[optionsResolver.name]) {
                throw new Error(
                    `your customOptionResolvers does not include optionsResolver ${optionsResolver.name}`,
                );
            }
            commonProps.items = customOptionResolvers[optionsResolver.name]({
                formContext,
                input: {
                    base: basePath,
                    name: input.name,
                    path: `${basePath}${input.name}`,
                    objectPath: basePath.replace(/\.$/, ''),
                },
                optionsResolverName: optionsResolver.name,
                params: optionsResolver.params || {},
            });
        }
        return (
            <ControlledSelectItems
                {...input}
                {...commonProps}
            />
        );
    }

    if (input.type === 'treeItems') {
        return <ControlledTreeItems {...input} />;
    }

    const error = resolvePath(`${basePath}${input.name}`, formState.errors) as
        | FieldError
        | undefined;

    if (input.type === 'select') {
        const { onChangeInput, defaultValue, optionsResolver }: FlexySelectFormStructure = input;

        const commonProps: {} & FlexyFormInputProps & FlexyInputProps<FlexySelectProps> = {
            ...inputsProps,
            ...(input as FlexyInputProps<FlexySelectProps>),
            name: `${basePath}${input.name}`,
            error: error !== undefined,
            helperText: error?.message,
            type: 'select',
            required: input.rules?.required ? true : undefined,
            defaultValue,
        };

        if (optionsResolver) {
            if (!customOptionResolvers) {
                throw new Error(
                    'you cant have optionsResolver in select without customOptionResolvers in form',
                );
            }
            if (!customOptionResolvers[optionsResolver.name]) {
                throw new Error(
                    `your customOptionResolvers does not include optionsResolver ${optionsResolver.name}`,
                );
            }
            commonProps.options = customOptionResolvers[optionsResolver.name]({
                formContext,
                input: {
                    base: basePath,
                    name: input.name,
                    path: `${basePath}${input.name}`,
                    objectPath: basePath.replace(/\.$/, ''),
                },
                optionsResolverName: optionsResolver.name,
                params: optionsResolver.params || {},
            });
        }

        return (
            <Controller
                name={`${basePath}${input.name}`}
                control={control}
                rules={input.rules}
                render={({ field }) => {
                    const { ref, onChange, ...props } = { ...field };

                    return (
                        <FlexyInput
                            inputRef={ref}
                            {...commonProps}
                            {...props}
                            onChange={(e: React.SyntheticEvent, val: any) => {
                                onChange(e, val);
                                if (onChangeInput) {
                                    onChangeInput(
                                        e,
                                        {
                                            base: basePath,
                                            name: input.name,
                                            path: `${basePath}${input.name}`,
                                            objectPath: basePath.replace(/\.$/, ''),
                                        },
                                        extandedFormContext,
                                    );
                                }
                            }}
                        />
                    );
                }}
            />
        ) as React.ReactElement<any, any>;
    }

    const { rules, onChangeInput, onBlurInput, defaultValue, ...inputRest } = input;

    const flexyInputProps = {
        ...inputsProps,
        ...inputRest,
    };

    if (input.type === 'autocompletestore') {
        const commonProps: {} & FlexyFormInputProps & FlexyTextFieldProps = {
            ...inputsProps,
            variant: 'outlined',
            name: `${basePath}${input.name}`,
            error: error !== undefined,
            helperText: error?.message,
            required: rules?.required ? true : undefined,
        };

        return (
            <ControlledAutocomplete
                {...input}
                name={`${basePath}${input.name}`}
                control={control}
                inputProps={{ ...commonProps }}
            />
        ) as React.ReactElement<any, any>;
    }

    if (input.type === 'boolean') {
        const commonProps: {} & FlexyFormInputProps & FlexyInputProps<SwitchProps> = {
            // ...inputsProps,
            ...(inputRest as FlexyInputProps<SwitchProps>),
            name: `${basePath}${input.name}`,
            type: 'boolean',
        };
        return (
            <Controller
                name={`${basePath}${input.name}`}
                control={control}
                defaultValue={defaultValue || false}
                render={({ field }) => {
                    const { ref, onChange, ...props } = { ...field };
                    return (
                        <FlexyInput
                            inputRef={ref}
                            {...commonProps}
                            {...props}
                            onChange={(e: React.SyntheticEvent, val: boolean) => {
                                onChange(e, val);
                                if (onChangeInput) {
                                    onChangeInput(
                                        e,
                                        {
                                            base: basePath,
                                            name: input.name,
                                            path: `${basePath}${input.name}`,
                                            objectPath: basePath.replace(/\.$/, ''),
                                        },
                                        extandedFormContext,
                                    );
                                }
                            }}
                        />
                    );
                }}
            />
        ) as React.ReactElement<any, any>;
    }

    if (input.type === 'custom') {
        if (input.name === 'dirigeants' || input.name === 'beneficiaires_effectif') {
            const Dirigeant1: DirigeantWidgetType = {
                dirigeant: {
                    prenom: 'Théo',
                    nom: 'Guerin',
                    fonction: 'Lead',
                },
                actions: [
                    {
                        name: 'preview',
                        icon: <FaOptionIcon {...preview.props} />,
                        onClick: () => true,
                    },
                    {
                        name: 'delete',
                        icon: <FaOptionIcon {...deleteIcon.props} />,
                        onClick: () => true,
                    },
                ],
            };

            const Dirigeant2: DirigeantWidgetType = {
                dirigeant: {
                    prenom: 'Mikaël',
                    nom: 'Rozuel',
                    fonction: 'Lead',
                },
                actions: [
                    {
                        name: 'preview',
                        icon: <FaOptionIcon {...preview.props} />,
                        onClick: () => true,
                    },
                    {
                        name: 'delete',
                        icon: <FaOptionIcon {...deleteIcon.props} />,
                        onClick: () => true,
                    },
                ],
            };

            return <DirigeantList items={[Dirigeant1, Dirigeant2]} />;
        }
        return null;
    }

    // dates
    if (typeDateList.indexOf(input.type) !== -1) {
        const { ...base } = {
            ...flexyInputProps,
            value: null,
        };

        const commonProps: {} & FlexyFormInputProps & FlexyInputProps<FlexyDateProps> = {
            ...base,
            type: input.type,
            name: `${basePath}${input.name}`,
            error: error !== undefined,
            helperText: error?.message,
            required: rules?.required ? true : undefined,
        };

        const dateValidation = (date: any) => {
            if (!date || date.toISOString()) {
                return true;
            }
            return 'Date invalide';
        };

        return (
            <Controller
                name={`${basePath}${input.name}`}
                rules={{
                    ...input.rules,
                    validate: {
                        isDate: (valueDate) => {
                            dateValidation(valueDate);
                            return true;
                        },
                        ...input.rules?.validate,
                    },
                }}
                control={control}
                render={({ field }) => {
                    const { ref, onChange, ...props } = { ...field };
                    return (
                        <FlexyInput
                            inputRef={ref}
                            {...commonProps}
                            {...props}
                            onChange={(e: React.SyntheticEvent, val: boolean) => {
                                onChange(e, val);
                                if (onChangeInput) {
                                    onChangeInput(
                                        e,
                                        {
                                            base: basePath,
                                            name: input.name,
                                            path: `${basePath}${input.name}`,
                                            objectPath: basePath.replace(/\.$/, ''),
                                        },
                                        extandedFormContext,
                                    );
                                }
                            }}
                        />
                    );
                }}
            />
        ) as React.ReactElement<any, any>;
    }

    const { ref, ...registred } = register(`${basePath}${input.name}`, {
        shouldUnregister,
        onChange: (e: React.SyntheticEvent) => {
            if (onChangeInput) {
                onChangeInput(
                    e,
                    {
                        base: basePath,
                        name: input.name,
                        path: `${basePath}${input.name}`,
                        objectPath: basePath.replace(/\.$/, ''),
                    },
                    extandedFormContext,
                );
            }
        },
        onBlur: (e: React.FocusEventHandler) => {
            if (onBlurInput) {
                onBlurInput(
                    e,
                    {
                        base: basePath,
                        name: input.name,
                        path: `${basePath}${input.name}`,
                        objectPath: basePath.replace(/\.$/, ''),
                    },
                    extandedFormContext,
                );
            }
        },
        ...rules,
    });

    const { ...base } = { ...flexyInputProps };

    const commonProps: {} & FlexyFormInputProps & FlexyInputProps = {
        variant: 'outlined',
        ...base,
        ...registred,
        inputRef: ref,
        type: input.type,
        name: `${basePath}${input.name}`,
        error: error !== undefined,
        helperText: error?.message,
        required: rules?.required ? true : undefined,
        disabled,
    };
    return <FlexyInput {...commonProps} />;
};

export default FormItemRenderer;
