import React, { FunctionComponent } from 'react';
import { List } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import FlexySelectMultipleItem from './FlexySelectMultipleItem';

export type FlexySelectItem = {
    label: string;
    value: string | number;
    checked: boolean;
    children: FlexySelectItem[];
};

export type FlexySelectMultipleItemsProps = {
    items: FlexySelectItem[];
    nested?: boolean;
    onChange: (value: Array<string | number>) => void;
};

const FlexySelectMultipleItems: FunctionComponent<FlexySelectMultipleItemsProps> = function (
    props,
) {
    const { items, nested = false, onChange } = props;

    return (
        <List
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '0',
            }}
        >
            {items.map((item) => (
                <FlexySelectMultipleItem
                    key={`${item.label}-${item.value}`}
                    item={item}
                    onChange={onChange}
                    nested={nested}
                />
            ))}
        </List>
    );
};

export default FlexySelectMultipleItems;
