import {
    parcoursFormaliteBasePath,
    parcoursFormaliteClientsStepPath,
    parcoursFormaliteDocsFirstStepPath,
    parcoursFormaliteDocsStepPath,
    parcoursFormaliteFormaliteStepPath,
    parcoursFormaliteFormStepPath,
    parcoursFormaliteRecapStepPath,
    type ParcoursFormaliteSupportedView,
} from './constants';

export const parcoursFormalitePathResolver = function (
    view: ParcoursFormaliteSupportedView,
): string {
    switch (view) {
        case 'stepFormalite':
            return ['', parcoursFormaliteBasePath, parcoursFormaliteFormaliteStepPath].join('/');
        case 'stepClient':
            return ['', parcoursFormaliteBasePath, parcoursFormaliteClientsStepPath].join('/');
        case 'stepPremiumDoc':
            return ['', parcoursFormaliteBasePath, parcoursFormaliteDocsFirstStepPath].join('/');
        case 'stepForm':
            return ['', parcoursFormaliteBasePath, parcoursFormaliteFormStepPath].join('/');
        case 'stepDocs':
            return ['', parcoursFormaliteBasePath, parcoursFormaliteDocsStepPath].join('/');
        case 'stepRecap':
            return ['', parcoursFormaliteBasePath, parcoursFormaliteRecapStepPath].join('/');
        default:
            throw new Error(`${view} routing not implemented`);
    }
};
