import { IconButton, InputAdornment } from '@mui/material';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import FlexyTextField from '../FlexyTextField';
import ExitButton from '../ExitButton';

export type SearchBarProps = {
    className?: string;
    value?: string;
    placeholder?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onDelete?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
};

const SearchBar: FunctionComponent<SearchBarProps> = function (props) {
    const { className, value, placeholder, onChange = () => {}, onDelete } = props;

    const [valueField, setValueField] = useState<string>(value || '');
    React.useEffect(() => {
        setValueField(value || '');
    }, [value]);

    return (
        <FlexyTextField
            className={className}
            variant="outlined"
            value={valueField}
            onChange={onChange}
            placeholder={placeholder}
            sx={{ width: '100%', marginTop: '25px' }}
            inputMode="search"
            InputProps={{
                sx: { borderRadius: '15px', backgroundColor: '#ffff' },
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: onDelete ? (
                    <InputAdornment position="end">
                        <ExitButton handleClickExitButton={onDelete} />
                    </InputAdornment>
                ) : null,
            }}
        />
    );
};

export default SearchBar;
