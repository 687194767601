import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import ChoiceItem, { ChoiceItemProps } from '../../atoms/ChoiceItem';
import ChoiceItemObjectGroup, { ChoiceItemGroupProps } from '../ChoiceItemObjectGroup';

export type ChoiceItemListProps = {
    label: string;
    listChoice: Array<ChoiceItemProps>;
    listGroupement?: Array<ChoiceItemGroupProps>;
    onChange?: (value: string) => void;
    multiple?: boolean;
    selectedValues?: string[];
    sx?: SxProps;
};

const ChoiceItemList: FunctionComponent<ChoiceItemListProps> = function ({
    label,
    listChoice,
    listGroupement,
    multiple = true,
    selectedValues = [],
    onChange = () => {},
    sx = {},
}) {
    return (
        <Box
            sx={{
                marginBottom: '15px',
                ...sx,
            }}
        >
            <Typography
                sx={{
                    paddingLeft: '15px',
                    paddingBottom: '8px',
                }}
                variant="l2dcaption"
                color="text.secondary"
            >
                {label}
            </Typography>
            <Box
                sx={{
                    marginTop: '5px',
                }}
            >
                {listChoice.map((choiceItem) => (
                    <ChoiceItem
                        key={choiceItem.value}
                        label={choiceItem.label}
                        siren={choiceItem.siren}
                        description={choiceItem.description}
                        isChecked={selectedValues.includes(choiceItem.value)}
                        value={choiceItem.value}
                        onChange={onChange}
                    />
                ))}
                {listGroupement?.map((choiceItemObjectGroup) => (
                    <ChoiceItemObjectGroup
                        key={choiceItemObjectGroup.label}
                        label={choiceItemObjectGroup.label}
                        selectedValues={selectedValues}
                        multiple={multiple}
                        onChange={onChange}
                        listObjectGroup={choiceItemObjectGroup.listObjectGroup}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ChoiceItemList;
