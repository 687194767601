import {
    CollectionDeDossierApi,
    Contact,
    DossierFormaliteApi,
    Prescripteur,
} from '@europrocurement/l2d-domain';

export const createDossierFormalite = async (
    prescripteur: Prescripteur,
    contact: Contact,
    idSousClients: Array<number>,
    idFormalite: Array<number>,
    dossierApi: DossierFormaliteApi,
    xIdSociete: number,
) => {
    if (!prescripteur.id) {
        throw new Error('missing prescriber');
    }
    if (idSousClients.length === 0) {
        throw new Error('missing client');
    }

    if (idFormalite.length === 0) {
        throw new Error('missing formality');
    }

    if (idSousClients.length > 1 || idFormalite.length > 1) {
        throw new Error('Multi Curently not implemented');
    }

    const res = await dossierApi.apiDossiersPost({
        dossierJsonldDossierCreate: {
            prescripteur: prescripteur.id,
            client: idSousClients[0],
            formalite: idFormalite[0],
            contact: contact.id,
            statut: 1,
            idFormeJuridique: 2,
            societe: xIdSociete,
            collection: null,
        },
    });
    if (!res.data.collection) {
        throw new Error('missing collection');
    }

    return res.data;
};

export const addDataToCollection = async (
    idCollection: number | string,
    collectionApi: CollectionDeDossierApi,
    data: string,
) => {
    const freshCollection = (
        await collectionApi.apiCollectionsDossiersIdGet({ id: `${idCollection}` })
    ).data;
    if (!freshCollection || !freshCollection.id) {
        throw new Error('error in Collection');
    }
    await collectionApi.apiCollectionsDossiersIdPut({
        id: `${idCollection}`,
        collectionDeDossierJsonldCollectiondossierUpdate: {
            ...freshCollection,
            prescripteur: freshCollection.prescripteur?.replace('/tiers/tiers', '/formalite/tiers'),
            client: freshCollection.client?.replace('/tiers/tiers', '/formalite/tiers'),
            infoComp: data,
        },
    });
};
