import * as React from 'react';
import { Button } from '@mui/material';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import { useNavigate } from 'react-router';
import { ErrorPage } from '../ErrorPage';

export const Forbidden = function () {
    const keycloak = UseKeycloakService();

    const navigate = useNavigate();

    return (
        <ErrorPage
            statusCode={403}
            message="Vous n'avez pas les droits d'acceder a cette page"
        >
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    navigate('/');
                    keycloak.getKc().logout();
                }}
            >
                Se déconnecter
            </Button>
        </ErrorPage>
    );
};
export default Forbidden;
