import React, { ChangeEvent, FunctionComponent } from 'react';
import { Button, useTheme } from '@mui/material';

export type ContinueButtonProps = {
    libelle?: string;
    onClick: (event: ChangeEvent<HTMLInputElement>) => void;
};

const ContinueButton: FunctionComponent<ContinueButtonProps> = function (props) {
    const { onClick = () => {}, libelle = 'Continuer' } = props;

    const theme = useTheme();
    return (
        <Button
            data-testid="test-id-continueButton"
            variant="contained"
            color="primary"
            sx={{
                backgroundColor: theme.palette.primary.dark,
                width: '100%',
                marginTop: '30px',
                padding: '16px 24px 16px 24px',
                fontSize: '1rem',
                lineHeight: '1.5',
                borderRadius: '15px',
            }}
            onClick={onClick}
        >
            {libelle}
        </Button>
    );
};

export default ContinueButton;
