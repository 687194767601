import * as React from 'react';
import { Box, PaletteColor, SxProps, useTheme } from '@mui/material';
import './ProgressBar.css';

export type ProgressBarProps = {
    completion?: number;
    color?: PaletteColor;
    minCompletion?: number;
    sx?: SxProps;
};

const ProgressBar: React.FunctionComponent<ProgressBarProps> = function (props: ProgressBarProps) {
    const theme = useTheme();
    const { color, completion, minCompletion = 5, sx } = props;
    const completionPercentage = Math.min(
        Math.max(completion || minCompletion, minCompletion),
        100,
    );
    const mainColor = color || theme.palette.primary;

    return (
        <Box
            className="progress-bar-wrapper"
            sx={{ backgroundColor: theme.palette.grey[200], ...sx }}
            data-testid="progress-bar"
        >
            <Box
                className="progress-bar-completion"
                sx={{ width: `${completionPercentage}%`, backgroundColor: mainColor.main }}
                data-testid="progress-bar-completion"
            />
        </Box>
    );
};

export default ProgressBar;
