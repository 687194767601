import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
    FilterDatatable,
    FiltersOpts,
    FlexyFilterValue,
    PreFilterDatatable,
} from '../DatatableHeader';
import FlexyFilterCleanup from '../FlexyFilterCleanup/FlexyFilterCleanup';
import FlexyFilter from '../FlexyFilter/FlexyFilter';
import useFilter from '../FlexyFilter/useFilter';
import FlexyPreFilters from '../FlexyPreFilters';

export type FieldAndValueFilter = {
    label: string;
    field: string;
    value: string;
};

export type FlexyFiltersListProps = {
    filtersOpts?: FiltersOpts;
};

const FlexyFiltersList: FunctionComponent<FlexyFiltersListProps> = function (props) {
    const { filtersOpts } = props;
    const { serializeValue, unserializeValue } = useFilter();
    const activeFilters = filtersOpts?.activeFilters || [];
    let cleanAllBtn = null;
    const [currentPreFilter, setCurrentPreFilter] = useState<string>('');
    const preFiltersProps = filtersOpts?.preFilters || [];
    const preFilters = [...preFiltersProps];

    useEffect(() => {
        setCurrentPreFilter(filtersOpts?.activePreFilter?.label || '');
    }, [filtersOpts?.activePreFilter]);

    useEffect(() => {
        if (preFilters.length > 0) {
            setCurrentPreFilter(preFilters[0].label);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (filter: FilterDatatable, value: FlexyFilterValue) => {
        if ((value === null || value === '') && filtersOpts?.onFilterDelete) {
            filtersOpts?.onFilterDelete(filter.field);

            return;
        }

        if (filtersOpts?.onFilterChange) {
            filtersOpts.onFilterChange(filter.field, serializeValue(filter, value));
        }
    };

    const handleClear = (filter: FilterDatatable) => {
        filtersOpts?.onFilterDelete(filter.field);
    };

    const handleCleanup = () => {
        filtersOpts?.onFiltersClear();
    };

    const applyPreFilter = (preFilter: PreFilterDatatable) => {
        setCurrentPreFilter(preFilter.label);
        filtersOpts?.onUpdateFilters(preFilter);
    };

    const filters = filtersOpts?.filters.map((filter: FilterDatatable) => {
        const value =
            activeFilters[filter.field] !== undefined ? activeFilters[filter.field] : null;

        return (
            <FlexyFilter
                key={filter.label}
                filter={filter}
                value={unserializeValue(filter, value)}
                onChange={handleChange}
                onClear={handleClear}
            />
        );
    });

    if (Object.keys(activeFilters).length > 0) {
        cleanAllBtn = <FlexyFilterCleanup onClick={handleCleanup} />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '0 8px',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginBottom: '1em',
            }}
        >
            {preFilters.length > 0 && (
                <FlexyPreFilters
                    filters={preFilters}
                    onClick={applyPreFilter}
                    current={currentPreFilter}
                />
            )}
            {filters}
            {cleanAllBtn}
        </Box>
    );
};

export default FlexyFiltersList;
