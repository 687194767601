import React from 'react';
import { Box } from '@mui/system';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { Typography } from '@mui/material';
import AddPrescriberClientButton from '@europrocurement/l2d-modules/modules/PrescriberClient/components/AddPrescriberClient/AddPrescriberClientButton';
import usePrescriber from '../../hook/usePrescriber';

const Accueil = function () {
    const { prescriberId } = usePrescriber();
    const { prescriberIds } = usePrescriber();

    return (
        <Box>
            <LazyBreadcrumb path={[{ path: 'Accueil', link: '/accueil' }, 'Dashboard']} />
            <Typography>id actuel {prescriberId}</Typography>
            <Typography>autres id {prescriberIds?.join(', ')}</Typography>
            <AddPrescriberClientButton
                onCancel={() => {
                    console.log('onCancel');
                }}
                onSuccess={(sousClient) => {
                    console.log('onSuccess', sousClient);
                }}
            />
        </Box>
    );
};
export default Accueil;
