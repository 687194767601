import React, { forwardRef, FunctionComponent, useEffect, useState } from 'react';
import { Box, CircularProgress, Input, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons';
import { FlexyFilterWidgetProps, SelectMultipleApiFilter } from '../DatatableHeader';
import FlexySelectMultipleItems, { FlexySelectItem } from './FlexySelectMultipleItems';

export type FlexySelectMultipleApiFilterProps = FlexyFilterWidgetProps & {};

const FlexySelectMultipleApiFilter: FunctionComponent = forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    <T extends Record<string, unknown>>(props: FlexyFilterWidgetProps, ref) => {
        const { filter, value, onChange } = props;
        const { placeholder, title } = filter;
        const [items, setItems] = useState<Array<T>>([]);
        const [searchTerm, setSearchTerm] = useState<string>('');
        const [loading, setLoading] = useState<boolean>(false);
        const [selectedValues, setSelectedValues] = useState<Array<string | number>>([]);
        const [displayedItems, setDisplayedItems] = useState<Array<FlexySelectItem>>([]);
        const theme = useTheme();

        const getDisplayedItems = () => {
            const selectItems: FlexySelectItem[] = [];

            items.map((item) => {
                const itemValue = (filter as SelectMultipleApiFilter<T>).getItemValue(item);
                const selectItem: FlexySelectItem = {
                    label: (filter as SelectMultipleApiFilter<T>).getGroupLabel(item),
                    value: (filter as SelectMultipleApiFilter<T>).getGroupValue(item),
                    checked: false,
                    children: [
                        {
                            label: (filter as SelectMultipleApiFilter<T>).getItemLabel(item),
                            value: itemValue,
                            checked: selectedValues.includes(itemValue),
                            children: [],
                        },
                    ],
                };

                const hasItem = selectItems.find((g) => g.value === selectItem.value);

                if (hasItem === undefined) {
                    selectItems.push(selectItem);
                } else {
                    hasItem.children.push(selectItem.children[0]);
                }

                return selectItem;
            });

            return selectItems;
        };

        const handleSearchTermChanged = (term: string) => {
            setSearchTerm(term);
        };

        const handleSelectItem = (vs: Array<string | number>) => {
            const proxyValues = [...selectedValues];

            vs.forEach((v) => {
                const index = proxyValues.indexOf(v);

                if (index !== -1) {
                    proxyValues.splice(index, 1);
                } else {
                    proxyValues.push(v);
                }
            });

            setSelectedValues(proxyValues);
        };

        useEffect(() => {
            setSelectedValues(value || []);
        }, [value]);

        useEffect(() => {
            setLoading(true);
            (filter as SelectMultipleApiFilter<T>).getItems(searchTerm).then((data: T) => {
                setItems(data);
                setLoading(false);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchTerm]);

        useEffect(() => {
            setDisplayedItems(getDisplayedItems());
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [items, selectedValues]);

        useEffect(() => {
            onChange(selectedValues);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedValues]);

        return (
            <Box
                sx={{
                    width: '100%',
                    padding: '10px',
                }}
            >
                <Box
                    sx={{
                        borderColor: theme.palette.grey[400],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        display: 'flex',
                        borderRadius: '12px',
                        alignItems: 'center',
                        padding: '0 10px',
                    }}
                >
                    <FaOptionIcon
                        color={theme.palette.text.primary}
                        icon={faSearch}
                    />
                    <Input
                        placeholder={placeholder || filter.label}
                        onChange={(e) => handleSearchTermChanged(e.target.value)}
                        value={searchTerm}
                        fullWidth
                        disableUnderline
                        inputProps={{
                            sx: {
                                color: theme.palette.text.primary,
                                height: '48px',
                                boxSizing: 'border-box',
                                padding: '8px 16px',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '32px',
                                textAlign: 'left',
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        padding: '5px 0',
                        overflowY: 'scroll',
                        maxHeight: '220px',
                    }}
                >
                    {title && (
                        <Box
                            sx={{
                                color: theme.palette.text.primary,
                                padding: '0px 14px',
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '25.2px',
                                textAlign: 'left',
                                marginBottom: '5px',
                            }}
                        >
                            {title}
                        </Box>
                    )}
                    <Box sx={{ color: theme.palette.text.primary }}>
                        {loading === true && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress sx={{ color: theme.palette.secondary.dark }} />
                            </Box>
                        )}
                        {loading === false && (
                            <FlexySelectMultipleItems
                                items={displayedItems}
                                onChange={handleSelectItem}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        );
    },
);

export default FlexySelectMultipleApiFilter;
