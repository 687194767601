import React, { useEffect, useState } from 'react';

import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    SxProps,
    useTheme,
} from '@mui/material';

import { Checkbox, Scrollbar, SkeletonArray } from '@europrocurement/flexy-components';
import type { Variant } from '@mui/material/styles/createTypography';

import type {
    EuroprocApiResponseStatus,
    Pagination as PaginationType,
} from '@europrocurement/l2d-redux-utils';
import { ColumnDatatable } from '../ColumnDatatable';
import FlexyFilters from '../FlexyFilters/FlexyFilters';
import { SearchOpts } from '../DatatableHeader';
import FlexyDatatableHeader from '../FlexyDatatableHeader/FlexyDatatableHeader';
import useSelectItem from './useSelectItem';
import { FlexyDatatableSelectionActionProps } from '../FlexyDatatableSelectionAction/FlexyDatatableSelectionAction';
import { FlexyDatatablePagination } from '../FlexyDatatablePagination';

export type DatatablePagination = PaginationType & {
    onPageChange: (page: number) => void;
    onItemsPerPageChange: (itemsPerPage: number) => void;
};

export type FlexyDatatableProps<T = Record<string, unknown>> = {
    status: EuroprocApiResponseStatus;
    columns: Array<ColumnDatatable<T>>;
    setColumns?: React.Dispatch<React.SetStateAction<ColumnDatatable<T>[]>>;
    data: Array<T>;
    columnLabelVariant?: Variant;
    textCellVariant?: Variant;
    pagination?: DatatablePagination;
    searchOpts?: SearchOpts;
    // handleExport?: DatatableHeaderProps['handleExport'];
    onClickRow?: (e: React.MouseEvent<HTMLElement>, item?: T) => void;
    onWheelClickRow?: (e: React.MouseEvent<HTMLElement>, item?: T) => void;
    filtersControl?: boolean;
    sx?: SxProps;
    hideColumnOptions?: boolean;
    rowsActions?: Array<FlexyDatatableSelectionActionProps>;
};

type FlexyDatatableData<T extends Record<string, unknown>> = T & {
    datatableIdentifier: string;
};

const tableCellSx: SxProps = {
    padding: '0px 8px',
    borderBottom: 'none',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
};

const FlexyDatatableTable = function <T extends Record<string, unknown>>({
    status,
    columns,
    setColumns,
    data,
    columnLabelVariant,
    textCellVariant,
    pagination,
    searchOpts,
    // handleExport,
    onClickRow,
    onWheelClickRow,
    filtersControl = true,
    hideColumnOptions = false,
    sx = {},
    rowsActions = [],
}: FlexyDatatableProps<T>) {
    const datatableDatas: Array<FlexyDatatableData<T>> = data.map((item, index) => ({
        ...item,
        datatableIdentifier: `datatableIdentifier${index}`,
    }));

    const { onPageChange, onItemsPerPageChange, itemsPerPage } = {
        ...pagination,
    } as DatatablePagination;

    const { isItemSelected, handleSelectItem, setCurrentData } = useSelectItem();
    const [rowHover, setRowHover] = useState('');
    const theme = useTheme();

    useEffect(() => {
        setCurrentData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function renderData() {
        switch (status) {
            case undefined:
            case 'succeeded':
                return (
                    <TableBody
                        data-testid="FlexyDatatableBody"
                        sx={{
                            backgroundColor: 'transparent',
                            overflow: 'hidden',
                        }}
                    >
                        {datatableDatas.map((item: FlexyDatatableData<T>) => {
                            let bgColor = 'inherit';

                            if (isItemSelected(item)) {
                                bgColor = 'primary.light';
                            }

                            return (
                                <TableRow
                                    key={item.datatableIdentifier}
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: isItemSelected(item)
                                                ? bgColor
                                                : 'transparent',
                                            boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                                        },
                                        height: '72px',
                                        backgroundColor: `${bgColor}`,
                                    }}
                                    onMouseEnter={() => setRowHover(item.datatableIdentifier)}
                                    onMouseLeave={() => setRowHover('')}
                                >
                                    {rowsActions.length > 0 && (
                                        <TableCell
                                            key={`${item.datatableIdentifier}-select-item`}
                                            sx={{
                                                ...tableCellSx,
                                                borderTopColor: theme.palette.grey[300],
                                                width: '60px',
                                            }}
                                        >
                                            <Checkbox
                                                checked={isItemSelected(item)}
                                                onChange={() => {
                                                    handleSelectItem(item);
                                                }}
                                                size="large"
                                                sx={{
                                                    opacity:
                                                        isItemSelected(item) ||
                                                        item.datatableIdentifier === rowHover
                                                            ? '1'
                                                            : '0',
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                    {columns?.map((column) =>
                                        column.isDisplayed ? (
                                            <TableCell
                                                key={`${item.datatableIdentifier}-${column.label}`}
                                                onClick={(e) =>
                                                    column.onClickCell
                                                        ? column.onClickCell
                                                        : onClickRow && onClickRow(e, item)
                                                }
                                                onAuxClick={(e) => {
                                                    const wheelClick = e.button === 1;
                                                    if (wheelClick) {
                                                        if (onWheelClickRow) {
                                                            onWheelClickRow(e, item);
                                                        }
                                                    }
                                                }}
                                                sx={{
                                                    ...tableCellSx,
                                                    width: column.fullWidth ? '100%' : 'auto',
                                                    borderTopColor: theme.palette.grey[300],
                                                }}
                                            >
                                                <Typography
                                                    variant={textCellVariant || 'body1'}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    {typeof column.render === 'string'
                                                        ? column.render in item &&
                                                          (item[column.render] as string)
                                                        : column.render(item)}
                                                </Typography>
                                            </TableCell>
                                        ) : null,
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                );
            case 'loading':
                return (
                    <TableBody data-testid="FlexyDatatableBody">
                        <TableRow>
                            <TableCell
                                data-testid="StatusLoading"
                                colSpan={12}
                                sx={{ height: '45vh' }}
                            >
                                <SkeletonArray
                                    withHeader={false}
                                    columns={4}
                                    rows={8}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                );
            case 'idle':
                return (
                    <TableBody data-testid="FlexyDatatableBody">
                        <TableRow>
                            <TableCell
                                colSpan={12}
                                sx={{ height: '45vh' }}
                            >
                                <Typography>En attente d&apos;une recherche...</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                );
            case 'failed':
            default:
                return (
                    <TableBody data-testid="FlexyDatatableBody">
                        <TableRow>
                            <TableCell
                                colSpan={12}
                                sx={{ height: '45vh' }}
                                data-testid="StatusFailed"
                            >
                                une erreur lors du chargement est survenue
                            </TableCell>
                        </TableRow>
                    </TableBody>
                );
        }
    }

    const paginationRef = React.useRef<HTMLDivElement>(null);
    const filterRef = React.useRef<HTMLDivElement>(null);

    return (
        <Box
            sx={{
                width: '100%',
                maxHeight: '100%',
            }}
        >
            {filtersControl ? (
                <Box ref={filterRef}>
                    <FlexyFilters searchOpts={searchOpts} />
                </Box>
            ) : null}
            <TableContainer
                sx={{
                    overflow: 'hidden',
                    maxHeight: `calc(100% - ${
                        (paginationRef?.current && paginationRef?.current.clientHeight) || 52
                    }px - ${(filterRef?.current && filterRef?.current.clientHeight) || 0}px )`,
                    ...sx,
                }}
            >
                <Scrollbar style={{ width: '100%' }}>
                    <Table
                        stickyHeader
                        aria-label="simple table"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: 'solid 1px',
                            borderColor: theme.palette.grey[300],
                            borderRadius: '12px',
                            backgroundColor: 'background.paper',
                        }}
                    >
                        <FlexyDatatableHeader
                            columns={columns}
                            searchOpts={searchOpts}
                            columnLabelVariant={columnLabelVariant}
                            hideColumnOptions={hideColumnOptions}
                            setColumns={setColumns}
                            itemsPerPage={Math.min(itemsPerPage, data.length)}
                            rowsActions={rowsActions}
                        />
                        {renderData()}
                    </Table>
                </Scrollbar>
            </TableContainer>
            {pagination && status === 'succeeded' ? (
                <Box data-testid="DatatablePagination">
                    <FlexyDatatablePagination
                        page={pagination.page}
                        rowsPerPage={itemsPerPage}
                        count={pagination.total || 0}
                        onRowsPerPageChange={onItemsPerPageChange}
                        onPageChange={onPageChange}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default FlexyDatatableTable;
