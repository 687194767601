import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useState,
    useMemo,
    useEffect,
} from 'react';
import { CatalogApiObject, Prescripteur, useFormaliteService } from '@europrocurement/l2d-domain';
import { useSelector } from 'react-redux';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { castIriToId } from '@europrocurement/l2d-utils';
import { useCurrentContact } from '@europrocurement/l2d-modules/hooks/useCurrentContact';
import { useSnackbar } from 'notistack';
import {
    ParcoursFormaliteContext,
    type ParcoursFormaliteContextType,
} from './ParcoursFormaliteContext';
import { createDossierFormalite } from './functions';

export type ModalProviderType = {
    children: ReactNode;
    prescripteur: Prescripteur;
    defaultCollectionId?: number;
    defaultDossierId?: number;
};

export const ParcoursFormaliteProvider: FunctionComponent<ModalProviderType> = function ({
    children,
    prescripteur,
    defaultCollectionId = -1,
    defaultDossierId = -1,
}) {
    const { dossierApi, collectionApi } = useFormaliteService();

    const contact = useCurrentContact();

    const { xIdSociete } = useSelector(customizerSelector);

    const [collections, setCollections] = useState<ParcoursFormaliteContextType['collections']>({});
    const [dossiers, setDossiers] = useState<ParcoursFormaliteContextType['dossiers']>({});

    const [currentCollectionId, setCurrentCollectionId] = useState<number>(defaultCollectionId);
    const [currentDossierId, setCurrentDossierId] = useState<number>(defaultDossierId);
    const [idFormalites, setIdFormalites] = useState<Array<number>>([]);
    const [idSousClients, setIdSousClients] = useState<Array<number>>([]);
    const [currentCatalog, setCurrentCatalog] = useState<CatalogApiObject | undefined>();
    const [currentCatalogs, setCurrentCatalogs] = useState<CatalogApiObject[]>();

    const isFormaliteSimple = () => false; // TODO : To be implemented
    const isFormaliteCombinee = () => false; // TODO : To be implemented
    const isFormaliteMultiple = () => false; // TODO : To be implemented
    const isFormaliteDupliquee = () => false; // TODO : To be implemented
    const { enqueueSnackbar } = useSnackbar();

    if (!prescripteur.id) {
        enqueueSnackbar('Missing id in prescriber', { variant: 'error' });
        throw new Error('missing id in prescriber');
    }

    const refreshCollection = useCallback(
        async (collectionId: number) => {
            const resCollection = await collectionApi.apiCollectionsDossiersIdGet({
                id: `${collectionId}`,
            });

            if (!resCollection.data.client || typeof resCollection.data.client !== 'string') {
                enqueueSnackbar('Missing client in collection', { variant: 'error' });
                throw new Error('missing client in collection');
            }

            setCollections((prev) => {
                const newCollection = { ...prev };
                newCollection[castIriToId(resCollection.data.client as string)] =
                    resCollection.data;

                console.log({ ...newCollection });

                return { ...newCollection };
            });
        },
        [collectionApi, enqueueSnackbar],
    );

    const refreshDossier = useCallback(
        async (dossierId: number) => {
            const resDossier = await dossierApi.apiDossiersIdGet({
                id: `${dossierId}`,
            });

            if (!resDossier.data.id) {
                enqueueSnackbar('Missing id in collection', { variant: 'error' });
                throw new Error('missing id in collection');
            }
            if (!resDossier.data.client) {
                enqueueSnackbar('Missing client in collection', { variant: 'error' });
                throw new Error('missing client in collection');
            }

            setDossiers((prev) => {
                const newDossier = { ...prev };
                newDossier[castIriToId(resDossier.data.client as unknown as string)] =
                    resDossier.data;

                return { ...newDossier };
            });
        },
        [dossierApi, enqueueSnackbar],
    );

    const create = useCallback(
        async (newIdSousClients: Array<number>, newIdFormalite: Array<number>) => {
            const dossier = await createDossierFormalite(
                prescripteur,
                contact,
                newIdSousClients,
                newIdFormalite,
                dossierApi,
                xIdSociete,
            );
            setIdFormalites(newIdFormalite);
            setIdSousClients(newIdSousClients);

            if (!dossier.collection) {
                enqueueSnackbar('Missing client in collection', { variant: 'error' });
                throw new Error('missing client in collection');
            }
            if (!dossier.id) {
                enqueueSnackbar('Missing id dossier', { variant: 'error' });
                throw new Error('missing id dossier');
            }

            const collectionFromIri = castIriToId(dossier.collection);
            setCurrentCollectionId(collectionFromIri);
            setCurrentDossierId(dossier.id);
            refreshCollection(collectionFromIri);
            refreshDossier(dossier.id);
        },
        [
            prescripteur,
            contact,
            dossierApi,
            xIdSociete,
            refreshCollection,
            refreshDossier,
            enqueueSnackbar,
        ],
    );

    useEffect(() => {
        if (Object.keys(collections).length === 0 && currentCollectionId !== -1) {
            refreshCollection(currentCollectionId);
        }
    }, [collections, currentCollectionId, refreshCollection]);

    useEffect(() => {
        if (Object.keys(dossiers).length === 0 && currentDossierId !== -1) {
            refreshDossier(currentDossierId);
        }
    }, [currentDossierId, dossiers, refreshDossier]);

    const parcoursFormaliteContextValue = useMemo<ParcoursFormaliteContextType>(() => {
        if (!prescripteur.id) {
            enqueueSnackbar('Missing id in prescriber', { variant: 'error' });
            throw new Error('missing id in prescriber');
        }
        return {
            collections,
            currentCollectionId,
            currentCatalog,
            currentCatalogs,
            currentDossierId,
            prescripteur,
            idFormalites,
            idSousClients,
            prescripteurId: prescripteur.id,
            dossiers,
            setIdSousClients,
            setIdFormalites,
            getPrescripteur: () => prescripteur,
            getCollections: () => collections,
            getCurrentCollection: () => {
                if (Object.keys(collections).length === 1) {
                    const idSousClient = Object.keys(collections)[0];
                    return collections[Number(idSousClient)];
                }
                return null;
            },
            getCurrentDossier: () => {
                if (Object.keys(dossiers).length === 1) {
                    const idSousClient = Object.keys(dossiers)[0];
                    return dossiers[Number(idSousClient)];
                }
                return null;
            },
            save: () => null,
            isFormaliteSimple,
            isFormaliteCombinee,
            isFormaliteMultiple,
            isFormaliteDupliquee,
            setCollections,
            create,
            setCurrentCatalog,
            setCurrentCatalogs,
            containerWidth: 'sm',
        };
    }, [
        prescripteur,
        collections,
        currentCollectionId,
        currentCatalog,
        currentCatalogs,
        currentDossierId,
        idFormalites,
        idSousClients,
        dossiers,
        create,
        enqueueSnackbar,
    ]);

    return (
        <ParcoursFormaliteContext.Provider value={parcoursFormaliteContextValue}>
            {children}
        </ParcoursFormaliteContext.Provider>
    );
};
