import React from 'react';
import { Box } from '@mui/system';
import { CatalogApiObject } from '@europrocurement/l2d-domain';
import { Typography } from '@mui/material';
import { FaOptionIcon, informationIcon } from '@europrocurement/l2d-icons';
import { SelectFormality } from '../../SelectFormality';

export type SelectionFormalityProps = {
    defaultGroups?: Array<number>;
    defaultCatalogs?: Array<number>;
    onChange?: (values: { ids: Array<number>; catalogs: CatalogApiObject[] }) => void;
    prescripteurId: number;
    origine?: number;
    domaine?: number;
    nomCommercial?: string;
};

const SelectionFormality: React.FunctionComponent<SelectionFormalityProps> = function (props) {
    return (
        <>
            <Typography
                component="h2"
                variant="l2dh4"
                sx={{
                    lineHeight: 1.4,
                }}
            >
                Quelle(s) formalité(s) souhaitez-vous réaliser ?
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '18px',
                    marginTop: '15px',
                    marginBottom: '15px',
                }}
            >
                <Typography
                    variant="l2dlabel"
                    color="text.secondary"
                >
                    <FaOptionIcon {...informationIcon.props} />
                </Typography>
                <Box
                    fontSize="14px"
                    marginLeft="8px"
                >
                    <Typography
                        variant="l2dlabel"
                        color="text.secondary"
                    >
                        Vous pouvez sélectionner plusieurs formalités à créer pour une même société.
                    </Typography>
                </Box>
            </Box>
            <Box>
                <SelectFormality {...props} />
            </Box>
        </>
    );
};

export default SelectionFormality;
