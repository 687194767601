import React, { useContext, useEffect, useState } from 'react';
import { Contact, Status } from '@europrocurement/l2d-domain';
import { Chip } from '@mui/material';
import { DossierContext } from '../../provider';

type StatusColumnProps = {
    statusIri: string;
};

const StatusColumn: React.FunctionComponent<StatusColumnProps> = function (props) {
    const { statusIri } = props;
    const [status, setStatus] = useState<Status | null>(null);
    const { getDossierStatus } = useContext(DossierContext);

    useEffect(() => {
        getDossierStatus(statusIri)
            .then((response: Contact) => {
                setStatus(response);
            })
            .catch(() => {
                setStatus(null);
            });
    }, [statusIri, getDossierStatus]);

    return status ? (
        <Chip
            color="primary"
            label={status.libelle}
        />
    ) : null;
};

export default StatusColumn;
