import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { PreFilterDatatable } from '../DatatableHeader';
import FlexyPreFilter from '../FlexyPreFilter/FlexyPreFilter';

export type FlexyPreFiltersProps = {
    filters: PreFilterDatatable[];
    current: string;
    onClick: (preFilter: PreFilterDatatable) => void;
};

const FlexyPreFilters: FunctionComponent<FlexyPreFiltersProps> = function (props) {
    const { filters, current, onClick } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                gap: '24px',
            }}
        >
            {filters.map((preFilter) => {
                const { label, count } = preFilter;

                return (
                    <FlexyPreFilter
                        label={label}
                        key={label}
                        current={current === label}
                        count={count}
                        onClick={() => onClick(preFilter)}
                    />
                );
            })}
        </Box>
    );
};

export default FlexyPreFilters;
