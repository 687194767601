import {
    CustomDivider,
    Information,
    InformationCard,
    InformationCardProps,
} from '@europrocurement/flexy-components';
import { DossierFormalite } from '@europrocurement/l2d-domain';
import { castIriToId, formatPrice } from '@europrocurement/l2d-utils';
import { Button, Card, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

type RecapFormalityShowProps = {
    dossier: DossierFormalite;
};

const RecapFormalityShow: React.FunctionComponent<RecapFormalityShowProps> = function ({
    dossier,
}) {
    const cardItemsSocite: InformationCardProps['items'] = [
        {
            label: 'Société concernée',
            value: dossier.societeDenomination,
        },
    ];
    const cardItemsFormalite: InformationCardProps['items'] = [
        {
            label: 'Type de formalité',
            value: dossier.libelleFormalite,
        },
    ];
    const cardItemsParams: InformationCardProps['items'] = [
        {
            label: 'xxx',
            value: 'xxx',
        },
    ];
    const snackbar = useSnackbar();

    // const cardItemsDocuments: InformationCardProps['items'] = [
    //     {
    //         label: 'Pouvoir',
    //         value: 'Pouvoir.pdf',
    //     },
    //     {
    //         label: 'Procès-verbal',
    //         value: 'pv.pdf',
    //     },
    //     {
    //         label: 'Justi de domicile',
    //         value: 'manquant',
    //     },
    // ];
    return (
        <>
            <Typography
                variant="h1"
                sx={{
                    marginBottom: '10px',
                }}
            >
                Récapitulatif du dossier {dossier.numero}
            </Typography>
            <Grid
                container
                flexWrap="nowrap"
            >
                <Grid
                    item
                    flexGrow="1"
                >
                    <InformationCard
                        title="Paramètres du dossier"
                        items={cardItemsParams}
                    />
                    <InformationCard
                        title="Facturation"
                        items={cardItemsParams}
                        button={{
                            label: 'Éditer les informations',
                            onClick: () => {
                                console.log('blette !');
                            },
                        }}
                    />
                    <InformationCard
                        title="Société"
                        items={cardItemsSocite}
                        button={{
                            label: 'Revenir à cette étape',
                            onClick: () => {
                                console.log('tomate !');
                            },
                        }}
                    />
                    <InformationCard
                        title="Formalité"
                        items={cardItemsFormalite}
                        button={{
                            label: 'Revenir à cette étape',
                            onClick: () => {
                                console.log('patate !');
                            },
                        }}
                    />

                    {dossier.documents &&
                    dossier.documents.filter(
                        (item) => item.statut && castIriToId(item.statut) === 7,
                    ) ? (
                        <InformationCard
                            title="Documents"
                            items={dossier.documents.map((document) => ({
                                label: document.libelle,
                                value: 'transmis ou pas',
                            }))}
                            button={{
                                label: 'Revenir à cette étape',
                                onClick: () => {
                                    console.log('courgette !');
                                },
                            }}
                        />
                    ) : null}
                </Grid>
                <Grid
                    item
                    sx={{ width: '30%' }}
                >
                    <Card>
                        <Typography
                            variant="h2"
                            sx={{
                                marginBottom: '10px',
                            }}
                        >
                            Prix estimatif
                        </Typography>
                        <Information
                            label="Frais de registre sousmis à la TVA"
                            value="xxx,xxx €"
                        />
                        <Information
                            label="Frais de registre non soumis à la TVA"
                            value="xxx,xxx €"
                        />
                        <Information
                            label="Annonce légale"
                            value="xxx,xxx €"
                        />
                        <Information
                            label="Honoraires"
                            value="xxx,xxx €"
                        />

                        <CustomDivider />
                        <Information
                            label="Prix HT"
                            value={`${formatPrice(dossier.ht)} €`}
                        />
                        <Information
                            label="TVA"
                            value={`${formatPrice(dossier.tva)} €`}
                        />
                        <CustomDivider />
                        <Information
                            label="Prix TTC"
                            value={`${formatPrice(dossier.ttc)} €`}
                        />
                    </Card>
                    <Card>
                        <Typography
                            variant="h4"
                            sx={{
                                marginBottom: '10px',
                            }}
                        >
                            <b>Commentaires</b> (facultatif)
                        </Typography>
                        <textarea
                            style={{ width: '100%', borderRadius: '10px', padding: '5px' }}
                            placeholder="Ajouter des informations complémentaires au dossier"
                        />
                    </Card>
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            snackbar.enqueueSnackbar({
                                message: 'Super dossier envoyé',
                            });
                        }}
                    >
                        Envoyer le dossier
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default RecapFormalityShow;
