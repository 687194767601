import { TypographyOptions } from '@mui/material/styles/createTypography';
import './fonts/fonts.css';
import './Theme.d';

const defaultL2D = {
    fontWeight: 500,
    fontFamily: 'Inter',
};

const defaultL2DHeading = {
    ...defaultL2D,
};

const typography: TypographyOptions = {
    // fontFamily: "'Roboto', sans-serif",
    // fontFamily: 'Verdana, Geneva, sans-serif',
    fontFamily: 'Inter, Geneva, sans-serif',
    // useNextVariants: true,
    h1: {
        fontWeight: 500,
        fontSize: '1.875rem', // 30px
        lineHeight: '1.5',
        fontFamily: 'Inter',
    },
    h2: {
        fontWeight: 500,
        fontSize: '1.5rem', // 24px
        lineHeight: '1.5',
        fontFamily: 'Inter',
    },
    h3: {
        fontWeight: 500,
        fontSize: '1.3125rem', // 21px
        lineHeight: '1.5',
        fontFamily: 'Inter',
    },
    h4: {
        fontWeight: 500,
        fontSize: '1.125rem', // 18px
        lineHeight: '1.5',
        fontFamily: 'Inter',
    },
    h5: {
        fontWeight: 500,
        fontSize: '1rem', // 16px
        lineHeight: '1.5',
        fontFamily: 'Inter',
    },
    h6: {
        fontWeight: 500,
        fontSize: '0.875rem', // 14px
        lineHeight: '1.5',
        fontFamily: 'Inter',
    },
    body1: {
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '1rem', // 16px
        lineHeight: 'auto',
    },
    body2: {
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '0.75rem', // 12px
        lineHeight: 'auto',
    },
    subtitle1: {
        fontSize: '1.2rem', // 24px
        fontFamily: 'Inter',
        fontWeight: 400,
        letterSpacing: '2px',
        lineHeight: '1',
    },
    subtitle2: {
        fontFamily: 'Inter',
        fontSize: '1rem', // 16px
        fontWeight: 400,
        lineHeight: '1',
    },
    button: {
        textTransform: 'none',
        fontWeight: 400,
    },

    l2dh1: {
        ...defaultL2DHeading,
        letterSpacing: '0.15rem',
        lineHeight: '1.2',
        fontSize: '3.75rem', // 60px
    },

    l2dh2: {
        ...defaultL2DHeading,
        lineHeight: '1.4',
        letterSpacing: '0.11rem',
        fontSize: '3.125rem', // 50px
    },

    l2dh3: {
        ...defaultL2DHeading,
        letterSpacing: '0.075rem',
        lineHeight: '1.4',
        fontSize: '2.25rem', // 36px
    },

    l2dh4: {
        ...defaultL2DHeading,
        lineHeight: '1.8',
        letterSpacing: '0.042rem',
        fontSize: '1.75rem', // 28px
    },

    l2dh5: {
        ...defaultL2DHeading,
        letterSpacing: '0.025rem',
        lineHeight: '1.8',
        fontSize: '1.5rem', // 24px
    },

    l2dh6: {
        ...defaultL2DHeading,
        lineHeight: '1.2',
        fontSize: '1.375rem', // 22px
        letterSpacing: '0.015rem',
    },

    l2dh7: {
        ...defaultL2DHeading,
        letterSpacing: '0.01rem',
        lineHeight: '1.2',
        fontSize: '1rem', // 16px
    },

    l2dbodyl: {
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '1.25rem', // 20px
        lineHeight: '1.8',
    },
    l2dbodym: {
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '1rem', // 16px
        lineHeight: '1.8',
    },
    l2dbodys: {
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '0.938rem', // 15px
        lineHeight: '1.4',
    },
    l2dbodyxs: {
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '0.813rem', // 13px
        lineHeight: '1.4',
    },
    l2dlabel: {
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '0.875rem', // 14px
        lineHeight: '1.8',
    },
    l2dcaption: {
        fontWeight: 500,
        fontFamily: 'Inter',
        fontSize: '0.875rem', // 14px
        lineHeight: '1.2',
    },
    l2dsubtitles: {
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '1rem', // 16px
        lineHeight: '1.5',
    },
};

export default typography;
