import * as React from 'react';
import { Button } from '@mui/material';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import { useNavigate } from 'react-router';
import { ErrorPage } from '../ErrorPage';

export const NoPrescriber = function () {
    const keycloak = UseKeycloakService();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!keycloak.isInitialized || !keycloak.isAutenticated || !keycloak.getTokenParsed()) {
            return;
        }

        const pId = keycloak.getTokenParsed()?.IdPrescripteur || null;

        if (pId) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak]);

    return (
        <ErrorPage
            statusCode={400}
            message="Les utilisateurs qui non ratachés a un compte prescripteur ne peuvent pas acceder a cette page"
        >
            <Button
                variant="contained"
                color="primary"
                onClick={() => keycloak.getKc().logout()}
            >
                Se déconnecter
            </Button>
        </ErrorPage>
    );
};
export default NoPrescriber;
