import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { useEffect, useState } from 'react';
import {
    selectedPrescripteursSelector,
    selectedStatusPrescripteursSelector,
    setIdPrescripteur,
    useIdPrescripteurCustomizer,
    useTiersService,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import _ from 'lodash';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { useAppDispatch, useAppSelector } from '../redux/types';

const usePrescriber = () => {
    const keycloak = UseKeycloakService();
    const dispatch = useAppDispatch();
    const prescriberIdCustomizer = useIdPrescripteurCustomizer();
    const [prescriberId, setPrescriberId] = useState<number | undefined>(prescriberIdCustomizer);
    const [prescriberIds, setPrescriberIds] = useState<Array<number> | undefined>([]);

    const prescriber = useAppSelector(selectedPrescripteursSelector);

    const { selectPrescripteur } = useTiersService();

    const { jobName, setJobName } = useFullLayout();

    const status = useTiersServiceSelector(selectedStatusPrescripteursSelector);

    useEffect(() => {
        if (!keycloak.isInitialized || !keycloak.isAutenticated || !keycloak.getTokenParsed()) {
            return;
        }

        const pId: Array<string> = keycloak.getTokenParsed()?.IdPrescripteur || null;
        if (status === 'idle') {
            if ((!pId && prescriberId !== undefined) || pId.length === 0) {
                console.log(pId, prescriberId);
                setPrescriberIds([]);
                setPrescriberId(undefined);
                return;
            }
            if (!prescriberIdCustomizer || pId.indexOf(`${prescriberIdCustomizer}`) === -1) {
                console.log("je set l'id par defaut", pId[0]);
                dispatch(selectPrescripteur({ id: pId[0] }));
                setPrescriberId(Number(pId[0]));
                setPrescriberIds(pId.map(Number));
                dispatch(setIdPrescripteur(Number(pId[0])));
            } else if (!_.isEqual(Number(prescriber?.id), Number(prescriberIdCustomizer))) {
                console.log(prescriber?.id, prescriberIdCustomizer);
                dispatch(selectPrescripteur({ id: prescriberIdCustomizer }));
                setPrescriberId(prescriberIdCustomizer);
                setPrescriberIds(pId.map(Number));
                console.log("je set l'id du customizer", prescriberIdCustomizer);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, status]);

    // set du jobname
    useEffect(() => {
        if (prescriber) {
            const currentContact = prescriber.contacts.find(
                (contact) =>
                    contact.emailsContact.find((email) => email.email === keycloak.getEmail()) !==
                    undefined,
            );
            if (currentContact && currentContact.fonction && currentContact.fonction !== jobName) {
                setJobName(currentContact.fonction);
            }
        }
    }, [jobName, keycloak, prescriber, setJobName]);

    return { prescriberId, prescriber, prescriberIds };
};

export default usePrescriber;
