import { useMemo } from 'react';
import { debounce } from '@mui/material';
import { SirenRead } from '@europrocurement/l2d-domain/openApi/ApiSiren';
import { useSirenService } from '@europrocurement/l2d-domain';
import { AddressFormatted } from './usePlaceAutocomplete';

export type CompanyType = AddressFormatted & {
    description: string;
    siren: string;
    denomination: string;
    addressFull: string;
    civilite: number;
    firstName: string;
    lastName: string;
};

const useSirenSearch = () => {
    const { sirenApi } = useSirenService();

    const format = (result: SirenRead): CompanyType => {
        const etablissement = result?.etablissements?.principal[0];
        let civilite = result?.civilite;

        if (civilite !== undefined) {
            civilite = civilite === '1' ? 102 : 103;
        }

        const fullAddressParts = [
            etablissement?.address?.adresse,
            etablissement?.address?.codePostal,
            etablissement?.address?.commune,
            etablissement?.address?.codePays,
        ].filter((part: string | null | undefined) => part !== null && part !== undefined);

        return {
            description: result?.siren
                ? `${result?.denomination} (${result.siren})`
                : result?.denomination,
            siren: result?.siren,
            denomination: result?.denomination,
            address: etablissement?.address?.adresse,
            postalCode: etablissement?.address?.codePostal,
            city: etablissement?.address?.commune,
            countryCode: etablissement?.address?.codePays.substring(0, 2),
            addressFull: fullAddressParts.join(', '),
            civilite,
            firstName: result?.nom,
            lastName: result?.prenom,
        };
    };

    const searchBySiren = async (siren: string): Promise<Array<CompanyType>> =>
        new Promise((resolve, reject) => {
            sirenApi
                .apiRcsSirenGet({ siren })
                .then((response) => {
                    resolve([format(response.data)]);
                })
                .catch(() => {
                    reject();
                });
        });

    const fetchSuggestions = useMemo(
        () =>
            debounce(
                (siren: string, callback: (suggestions: CompanyType[]) => void) =>
                    searchBySiren(siren)
                        .then((results) => {
                            callback(results);
                        })
                        .catch(() => {
                            callback([]);
                        }),
                400,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return { fetchSuggestions, searchBySiren };
};

export default useSirenSearch;
