import React, { useContext, useEffect, useState } from 'react';
import { Utilisateur } from '@europrocurement/l2d-domain/reducers/utilisateurs';
import { DossierContext } from '../../provider';
import PeoplePreview from '../PeoplePreview';

type FormalisteColumnProps = {
    formalisteIri: string;
};

const FormalisteColumn: React.FunctionComponent<FormalisteColumnProps> = function (props) {
    const { formalisteIri } = props;
    const [formaliste, setFormaliste] = useState<Utilisateur | null>(null);
    const { getFormaliste } = useContext(DossierContext);

    useEffect(() => {
        getFormaliste(formalisteIri)
            .then((response) => {
                setFormaliste(response);
            })
            .catch(() => {
                setFormaliste(null);
            });
    }, [formalisteIri, getFormaliste]);

    return formaliste ? (
        <PeoplePreview
            firstName={formaliste.prenom}
            lastName={formaliste.nom}
        />
    ) : null;
};

export default FormalisteColumn;
