import React, { useEffect } from 'react';
import {
    FileUpload,
    getBase64Statut,
    Previous,
    Stepper,
    TypedRequiredFile,
    UploadedFile,
    useUploader,
} from '@europrocurement/flexy-components';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import {
    DossierJsonldDossierRead,
    FichiersDossierApi,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { Button, Collapse, Divider, List, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import { TransitionGroup } from 'react-transition-group';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { useParcoursFormalite } from '../provider/useParcoursFormalite';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';

const loadDossier = (dossier: DossierJsonldDossierRead, fichiersDossierApi: FichiersDossierApi) => {
    fichiersDossierApi.apiDossiersDossierFichiersGetCollection({
        dossier: `${dossier.id}`,
    });
};

const uploadDossierFiles = async (
    dossier: DossierJsonldDossierRead,
    fichiersDossierApi: FichiersDossierApi,
    files: File[],
) => {
    await Promise.all(
        files.map(async (file) => {
            await fichiersDossierApi.apiDossiersDossierFichiersPost({
                dossier: `${dossier.id}`,
                file,
            });
        }),
    );
};

const StepDocuments: React.FunctionComponent = function () {
    const { getCurrentDossier, containerWidth } = useParcoursFormalite();

    const { files, addFiles, removeFile, filesBase64 } = useUploader();

    const dossier = getCurrentDossier();
    const navigate = useNavigate();
    const theme = useTheme();
    const { fichiersDossierApi } = useFormaliteService();

    useEffect(() => {
        if (dossier && dossier.id) {
            loadDossier(dossier, fichiersDossierApi);
        }
    }, [dossier, fichiersDossierApi]);

    const onPrevious = () => {
        navigate(parcoursFormalitePathResolver('stepForm'));
    };

    const { setCustomHeader } = useFullLayout();

    React.useEffect(() => {
        setCustomHeader(
            <Box sx={{ width: '100%', padding: 'auto' }}>
                <Container maxWidth="md">
                    <Stepper
                        steps={[
                            {
                                name: 'step_company',
                                text: 'Société',
                                progress: 100,
                            },
                            {
                                name: 'step_formality',
                                text: 'Formalité',
                                progress: 100,
                            },
                            {
                                name: 'step_documents',
                                text: 'Documents',
                                progress: 15,
                            },
                            {
                                name: 'step_summary',
                                text: 'Récapitulatif',
                                progress: 0,
                            },
                        ]}
                    />
                </Container>
            </Box>,
        );
        return () => {
            setCustomHeader(null);
        };
    }, [setCustomHeader]);

    const fileUploadProps = {
        btnText: 'Sélectionnez',
        dropText: ' ou glissez vos fichiers ici',
        formatInfo: '.pdf .doc .jpg .xls',
        disableFormat: true,
        maxMbSize: 10,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target.files?.length > 0) {
                addFiles(event.target.files);
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            addFiles(event.dataTransfer.files);
        },
        accept: 'application/pdf,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        additionalInfo: (
            <Box sx={{ marginTop: '-10px', textAlign: 'center' }}>
                <Typography
                    variant="l2dbodyxs"
                    sx={{ fontWeight: 700 }}
                    color="text.secondary"
                >
                    Gagnez du temps :
                </Typography>
                <Typography
                    variant="l2dbodyxs"
                    sx={{ fontWeight: 500 }}
                    color="text.secondary"
                >
                    {' '}
                    déposez ici tous les documents nécessaires,
                </Typography>
                <Typography
                    component="p"
                    variant="l2dbodyxs"
                    color="text.secondary"
                >
                    nous les trierons pour vous.
                </Typography>
            </Box>
        ),
    };

    return (
        <Container maxWidth={containerWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <CurrentFormalityAndClient />
            </Box>
            <Previous onClick={onPrevious} />
            <Typography variant="l2dh4">Veuillez envoyer les documents manquants</Typography>
            <Typography
                sx={{ fontWeight: 500 }}
                variant="l2dbodyxs"
                color="text.secondary"
            >
                Formats : {fileUploadProps.formatInfo} - Poids maximum : 10Mo
            </Typography>
            <Box sx={{ paddingTop: '40px' }}>
                <FileUpload {...fileUploadProps} />
            </Box>

            <List>
                <TransitionGroup>
                    {files.map((file: File, index) => (
                        <Collapse
                            timeout={300}
                            key={`fichier-${file.name}-${file.size}-${file.lastModified}`}
                        >
                            <UploadedFile
                                mode="inline"
                                key={`${file.name}-${file.size}-${file.lastModified}`}
                                file={file}
                                status={getBase64Statut(filesBase64[index])}
                                onDelete={() => {
                                    removeFile(index);
                                }}
                            />
                            {index < files.length - 1 ? <Divider /> : null}
                        </Collapse>
                    ))}
                </TransitionGroup>
            </List>

            <Box sx={{ paddingTop: '40px' }}>
                <Typography
                    variant="l2dbodym"
                    fontWeight={700}
                >
                    Liste des documents à envoyer
                </Typography>
                <Typography
                    sx={{ marginLeft: '5px' }}
                    variant="l2dbodym"
                    color="text.secondary"
                >
                    (facultatif)
                </Typography>
            </Box>
            {dossier?.documents
                ?.map((typedoc) => ({
                    libelle: typedoc.libelle || 'pas de libelle',
                    id: typedoc.id || -1,
                }))
                .map((requiredType) => <TypedRequiredFile {...requiredType} />)}
            <Button
                type="submit"
                className="submit-btn"
                variant="contained"
                color="primary"
                data-test-id="submit-create-group"
                onClick={async () => {
                    if (dossier) {
                        await uploadDossierFiles(dossier, fichiersDossierApi, files);
                        navigate(parcoursFormalitePathResolver('stepRecap'));
                    }
                }}
                sx={{
                    backgroundColor: theme.palette.primary.dark,
                    width: '100%',
                    marginTop: '30px',
                    padding: '15px',
                    fontSize: '1rem',
                    borderRadius: '15px',
                }}
            >
                Continuer
            </Button>
        </Container>
    );
};

export default StepDocuments;
