import { FormStructure } from '@europrocurement/flexy-form/components';
import { jsonSubForm } from '../types/formTypes';
import jsonSubformArrayToStructure from './jsonSubformArrayToStructure';
// eslint-disable-next-line import/no-cycle
import jsonPageSectionToStructure from './jsonPageSectionToStructure';

const jsonSubformToStructure: (subForm: jsonSubForm) => FormStructure = (subform) => {
    const { minlength = 0, maxlength = Infinity, rules, sections, ...base } = subform;

    if (rules) {
        console.warn('rules to be implement');
    }

    if (!(minlength === 1 && maxlength === 1)) {
        return jsonSubformArrayToStructure(subform);
    }

    const subForm: FormStructure = {
        ...base,
        type: 'subform',
        defaultlength: 1,
        minlength: 1,
        maxlength: 1,
        structure: sections.map(jsonPageSectionToStructure),
    };

    return subForm;
};

export default jsonSubformToStructure;
