import React, { forwardRef, FunctionComponent, useEffect, useState } from 'react';
import { Box, CircularProgress, Input, List, ListItem, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faCheck, faSearch } from '@fortawesome/pro-duotone-svg-icons';
import { FlexyFilterWidgetProps, SelectApiFilter } from '../DatatableHeader';

const FlexySelectApiFilter: FunctionComponent = forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    <T extends Record<string, unknown>>(props: FlexyFilterWidgetProps, ref) => {
        const { filter, value, onChange } = props;
        const [items, setItems] = useState<Array<T>>([]);
        const [searchTerm, setSearchTerm] = useState<string>('');
        const [loading, setLoading] = useState<boolean>(false);
        const [selectValue, setSelectValue] = useState<string | boolean | null>(null);
        const theme = useTheme();

        useEffect(() => {
            setSelectValue(value);
        }, [value]);

        useEffect(() => {
            setLoading(true);
            (filter as SelectApiFilter<T>).searchFunction(searchTerm).then((data: T) => {
                setItems(data);
                setLoading(false);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchTerm]);

        const handleSearchTermChanged = (term: string) => {
            setSearchTerm(term);
        };

        const handleSelectItem = (item: unknown) => {
            setSelectValue((filter as SelectApiFilter<T>).valueMap(item));
            onChange((filter as SelectApiFilter<T>).valueMap(item));
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    padding: '10px',
                }}
            >
                <Box
                    sx={{
                        borderColor: theme.palette.grey[400],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        display: 'flex',
                        borderRadius: '12px',
                        alignItems: 'center',
                        padding: '0 10px',
                    }}
                >
                    <FaOptionIcon
                        color={theme.palette.text.primary}
                        icon={faSearch}
                    />
                    <Input
                        placeholder={filter.label}
                        onChange={(e) => handleSearchTermChanged(e.target.value)}
                        value={searchTerm}
                        fullWidth
                        disableUnderline
                        inputProps={{
                            sx: {
                                color: theme.palette.text.primary,
                                height: '48px',
                                boxSizing: 'border-box',
                                padding: '8px 16px',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '32px',
                                textAlign: 'left',
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        padding: '5px 0',
                        overflowY: 'scroll',
                        maxHeight: '220px',
                    }}
                >
                    <Box
                        sx={{
                            color: theme.palette.text.primary,
                            padding: '0px 14px',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '25.2px',
                            textAlign: 'left',
                            marginBottom: '5px',
                        }}
                    >
                        <Box>{filter.label}</Box>
                        {loading === true && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress sx={{ color: theme.palette.secondary.dark }} />
                            </Box>
                        )}
                        {loading === false && items.length > 0 && (
                            <List>
                                {items.map((item: unknown, index: number) => {
                                    const selected =
                                        selectValue ===
                                        (filter as SelectApiFilter<T>).valueMap(item);

                                    return (
                                        <ListItem
                                            onClick={() => handleSelectItem(item)}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                            sx={{
                                                backgroundColor: selected ? 'primary.light' : '',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {(filter as SelectApiFilter<T>).renderItem(item)}
                                            {selected && (
                                                <FaOptionIcon
                                                    sx={{ color: selected ? 'primary.main' : '' }}
                                                    icon={faCheck}
                                                />
                                            )}
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </Box>
                </Box>
            </Box>
        );
    },
);

export default FlexySelectApiFilter;
