import React, { useState, FunctionComponent, useEffect } from 'react';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faBarsFilter, faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import { PreFilterDatatable, SearchOpts } from '../DatatableHeader';
import FlexyFiltersList from '../FlexyFiltersList';
import FlexyPreFilters from '../FlexyPreFilters';

export type FlexyFiltersProps = {
    searchOpts?: SearchOpts;
};

const FlexyFilters: FunctionComponent<FlexyFiltersProps> = function ({
    searchOpts,
}: FlexyFiltersProps) {
    const activeFiltersCount = Object.keys(searchOpts?.filtersOpts?.activeFilters || {}).length;
    const filtersCount = Object.keys(searchOpts?.filtersOpts?.filters || {}).length;
    const [showFilter, setShowFilters] = useState<boolean>(activeFiltersCount > 0);
    const [currentPreFilter, setCurrentPreFilter] = useState<string>('');
    const preFiltersProps = searchOpts?.filtersOpts?.preFilters || [];
    const preFilters = [...preFiltersProps];
    const theme = useTheme();

    useEffect(() => {
        setCurrentPreFilter(searchOpts?.filtersOpts?.activePreFilter?.label || '');
    }, [searchOpts?.filtersOpts?.activePreFilter]);

    useEffect(() => {
        if (preFilters.length > 0) {
            setCurrentPreFilter(preFilters[0].label);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!filtersCount) {
        return null;
    }

    const applyPreFilter = (preFilter: PreFilterDatatable) => {
        setCurrentPreFilter(preFilter.label);
        searchOpts?.filtersOpts?.onUpdateFilters(preFilter);
    };

    const counter =
        activeFiltersCount > 0 ? (
            <Box
                sx={{
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '14.4px',
                    textAlign: 'center',
                    padding: '2px 5px 2px 5px',
                    borderRadius: '16px',
                    backgroundColor: theme.palette.primary.light,
                }}
            >
                {activeFiltersCount}
            </Box>
        ) : null;
    let chevron = null;
    const chevronStyle = {
        fontSize: '8px',
        lineHeight: '8px',
    };

    if (
        searchOpts?.filtersOpts?.filters !== undefined &&
        searchOpts?.filtersOpts?.filters?.length > 0
    ) {
        chevron = showFilter ? (
            <Box sx={chevronStyle}>
                <FaOptionIcon icon={faChevronDown} />
            </Box>
        ) : (
            <Box sx={chevronStyle}>
                <FaOptionIcon icon={faChevronUp} />
            </Box>
        );
    }

    const toggleFiltersButton = (
        <Button
            sx={{
                padding: '12px 16px 12px 16px',
                borderRadius: '12px',
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.primary.dark,
                borderColor: theme.palette.grey[300],
            }}
            onClick={() => {
                setShowFilters((v) => !v);
            }}
        >
            <FaOptionIcon icon={faBarsFilter} />
            Filtrer
            {counter}
            {chevron}
        </Button>
    );

    return (
        <Grid
            container
            direction="column"
            alignItems="end"
        >
            <Grid sx={{ width: '100%' }}>
                <Grid
                    container
                    flexDirection="row-reverse"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid>{toggleFiltersButton}</Grid>
                    {preFilters.length > 0 && (
                        <Grid>
                            <FlexyPreFilters
                                filters={preFilters}
                                onClick={applyPreFilter}
                                current={currentPreFilter}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid
                alignSelf="start"
                sx={{ marginTop: '30px' }}
            >
                {showFilter && <FlexyFiltersList filtersOpts={searchOpts?.filtersOpts} />}
            </Grid>
        </Grid>
    );
};

export default FlexyFilters;
