import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
    selectedSousClientSelector,
    SousClientMainSelector,
    TiersAppDispatch,
    useTiersService,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParcoursFormalite } from '../provider';

const CurrentFormalityAndClient: React.FunctionComponent = function () {
    const { currentCatalog, idSousClients } = useParcoursFormalite();

    const sousClientDs = useTiersServiceSelector(SousClientMainSelector);
    const sousClient = useTiersServiceSelector(selectedSousClientSelector);
    const { selectSousClients } = useTiersService();
    const dispatch = useDispatch<TiersAppDispatch>();

    useEffect(() => {
        if (sousClientDs.selectedStatus !== 'loading' && idSousClients && idSousClients[0]) {
            if (sousClient && sousClient.id && !_.isEqual(idSousClients[0], sousClient.id)) {
                console.log('je vais le chercher 1', sousClient, idSousClients[0]);
                dispatch(selectSousClients({ id: idSousClients[0] }));
            } else if (!sousClient) {
                console.log('je vais le chercher 2');
                dispatch(selectSousClients({ id: idSousClients[0] }));
            }
        } else {
            console.log();
        }
    }, [dispatch, idSousClients, selectSousClients, sousClient, sousClientDs.selectedStatus]);

    return currentCatalog ? (
        <Box
            sx={{
                with: '100%',
                textAlign: 'center',
            }}
        >
            <Typography
                variant="l2dbodyxs"
                color="text.secondary"
                sx={{
                    fontWeight: 500,
                    fontStyle: 'italic',
                }}
            >
                {' '}
                {currentCatalog.nomCommercial} {sousClient ? ` - ${sousClient.raisonSociale}` : ''}
            </Typography>
        </Box>
    ) : null;
};

export default CurrentFormalityAndClient;
