/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, ReactNode, useEffect, useMemo } from 'react';
import {
    Contact,
    Status,
    useFormaliteService,
    useTiersService,
    useUtilisateurService,
    Utilisateur,
} from '@europrocurement/l2d-domain';
import { castIriToId } from '@europrocurement/l2d-utils';
import { AxiosResponse } from 'axios';
import { DossierContext } from './DossierContext';

export type DossierProviderType = {
    children: ReactNode;
};

let pendings: Record<string, Array<unknown>> = {};
let data: Record<string, unknown> = {};

const getData = (iri: string, apiMethod: (identifier: number) => Promise<AxiosResponse>) =>
    new Promise((resolve, reject) => {
        const identifier = castIriToId(iri);

        if (undefined !== data[iri]) {
            resolve(data[iri]);

            return;
        }

        if (undefined !== pendings[iri]) {
            pendings[iri].push({ resolve, reject });

            return;
        }

        pendings[iri] = [];
        pendings[iri].push({ resolve, reject });

        apiMethod(identifier)
            .then((response) => {
                data[iri] = response.data;
                pendings[iri].forEach(({ resolve: pendingResolve }) => {
                    pendingResolve(response.data);
                });
            })
            .catch(() => {
                pendings[iri].forEach(({ reject: pendingReject }) => {
                    pendingReject();
                });
            })
            .finally(() => {
                pendings[iri] = [];
            });
    });

export const DossierProvider: FunctionComponent<DossierProviderType> = function ({ children }) {
    const { utilisateurApi } = useUtilisateurService();
    const { contactsApi } = useTiersService();
    const { statusApi } = useFormaliteService();

    useEffect(
        () => () => {
            pendings = {};
            data = {};
        },
        [],
    );

    const getFormaliste = (iri: string): Promise<Utilisateur> =>
        getData(iri, (id: number) =>
            utilisateurApi.apiUtilisateursIdGet({ id: id as unknown as string }),
        ) as Promise<Utilisateur>;

    const getContact = (iri: string): Promise<Contact> =>
        getData(iri, (id: number) =>
            contactsApi.getContactContactsItem({ id: id as unknown as string }),
        ) as Promise<Contact>;

    const getDossierStatus = (iri: string): Promise<Status> =>
        getData(iri, (id: number) =>
            statusApi.apiStatutsIdGet({ id: id as unknown as string }),
        ) as Promise<Status>;

    const contextValue = useMemo(
        () => ({ getFormaliste, getContact, getDossierStatus }),
        [getFormaliste, getContact, getDossierStatus],
    );

    return <DossierContext.Provider value={contextValue}>{children}</DossierContext.Provider>;
};
