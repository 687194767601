import React from 'react';
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import type { Variant } from '@mui/material/styles/createTypography';
import { Checkbox } from '@europrocurement/flexy-components';
import ColumnHeaderItemSortabled, { SortDirection } from '../ColumnHeaderItemSortabled';
import { ColumnDatatable } from '../ColumnDatatable';
import { SearchOpts } from '../DatatableHeader';
import useSelectItem from '../FlexyDatatableTable/useSelectItem';
import FlexyDatatableSelectionAction, {
    FlexyDatatableSelectionActionProps,
} from '../FlexyDatatableSelectionAction/FlexyDatatableSelectionAction';

export type FlexyDatatableHeaderProps<T = Record<string, unknown>> = {
    columns: Array<ColumnDatatable<T>>;
    searchOpts?: SearchOpts;
    columnLabelVariant?: Variant;
    itemsPerPage?: number;
    hideColumnOptions?: boolean;
    setColumns?: React.Dispatch<React.SetStateAction<ColumnDatatable<T>[]>>;
    rowsActions?: Array<FlexyDatatableSelectionActionProps>;
};

const FlexyDatatableHeader = function <T extends Record<string, unknown>>(
    props: FlexyDatatableHeaderProps<T>,
) {
    const {
        columns,
        searchOpts,
        columnLabelVariant,
        itemsPerPage,
        hideColumnOptions = false,
        setColumns = null,
        rowsActions = [],
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const openDisplayColumn = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const countDisplayed = columns.filter((column) => column.isDisplayed).length;

    const { countSelectedItems, hasSelectedItems, handleSelectAllItems, countUnselectedItems } =
        useSelectItem();
    const selectAllCkb = (
        <Checkbox
            checked={countUnselectedItems() === 0}
            indeterminate={
                itemsPerPage !== undefined &&
                countUnselectedItems() > 0 &&
                countUnselectedItems() < itemsPerPage
            }
            onChange={handleSelectAllItems}
            size="small"
        />
    );

    const celStyle = {
        padding: '16px 16px 16px 4px ',
        textAlign: 'left',
        verticalAlign: 'middle',
        justifyContent: 'flex-start',
        backgroundColor: 'background.default',
        borderTopColor: theme.palette.grey[300],
        borderBottomColor: 'transparent',
        maxHeight: '55px',
    };

    if (hasSelectedItems() && rowsActions.length > 0) {
        const highlightStyle = {
            ...celStyle,
            backgroundColor: 'primary.main',
            color: 'primary.dark.contrastText',
            fontSize: '14px',
            lineHeight: '25.2px',
            width: 'auto',
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell
                        colSpan={countDisplayed + 1}
                        sx={{
                            ...highlightStyle,
                            borderTopLeftRadius: '12px',
                            borderTopRightRadius: '12px',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {selectAllCkb}
                            <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <Box>{countSelectedItems()} sélection(s)</Box>
                                {rowsActions.map((rowAction) => (
                                    <FlexyDatatableSelectionAction
                                        key={rowAction.label}
                                        {...rowAction}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    return (
        <TableHead data-testid="FlexyDatatableHead">
            <TableRow>
                {rowsActions.length > 0 && (
                    <TableCell
                        key="select-item-cell"
                        sx={{
                            ...celStyle,
                            borderTopLeftRadius: '12px',
                            width: '60px',
                        }}
                    >
                        {selectAllCkb}
                    </TableCell>
                )}
                {columns?.map((column, i, arr) => {
                    if (arr.length - 1 !== i) {
                        let orderable = false;
                        let defaultOrder: SortDirection;
                        if (column.sortable && searchOpts?.ordersOpts?.orders) {
                            orderable = true;
                            if (column.field && searchOpts.ordersOpts?.orders) {
                                const currentOrder = searchOpts.ordersOpts.orders.find(
                                    (item) => item.field === column.field,
                                );
                                if (currentOrder) {
                                    defaultOrder = currentOrder.value as SortDirection;
                                }
                            }
                        }

                        return (
                            column.isDisplayed && (
                                <TableCell
                                    key={column.label}
                                    sx={{
                                        ...celStyle,
                                        borderTopLeftRadius:
                                            i === 0 && rowsActions.length <= 0 ? '12px' : '0px',
                                    }}
                                >
                                    <Typography
                                        variant={columnLabelVariant}
                                        sx={{
                                            color: theme.palette.grey[600],
                                            fontFamily: 'Inter',
                                            fontSize: '13px',
                                            fontWeight: '600',
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                        }}
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        {!(column.displayLabel === false) && column.label}
                                        {orderable && searchOpts?.ordersOpts ? (
                                            <ColumnHeaderItemSortabled
                                                onOrdersChange={
                                                    searchOpts.ordersOpts?.onOrdersChange
                                                }
                                                defaultValue={defaultOrder}
                                                field={column.field}
                                            />
                                        ) : null}
                                    </Typography>
                                </TableCell>
                            )
                        );
                    }

                    return (
                        <TableCell
                            key={column.label}
                            sx={{
                                ...celStyle,
                                borderTopRightRadius: '12px',
                            }}
                            data-testid="FiltersColumn"
                        >
                            {!hideColumnOptions && (
                                <>
                                    <Tooltip
                                        title="affichage colonnes"
                                        data-testid="DisplayFiltersButton"
                                    >
                                        <IconButton onClick={openDisplayColumn}>
                                            <MoreVertIcon
                                                sx={{
                                                    color: 'inherit',
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 48 * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {columns?.slice(0, -1).map((currentColumn, index) => (
                                            <MenuItem
                                                key={currentColumn.label}
                                                selected={currentColumn.isDisplayed}
                                                onClick={() => {
                                                    const newCols = [...columns];
                                                    newCols[index].isDisplayed =
                                                        !newCols[index].isDisplayed;
                                                    if (setColumns) setColumns(newCols);
                                                }}
                                            >
                                                {currentColumn.isDisplayed && (
                                                    <CheckIcon fontSize="small" />
                                                )}
                                                {currentColumn.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default FlexyDatatableHeader;
