import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/system';
import { Button, Typography, useTheme } from '@mui/material';
import { SelectItem } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Catalog, Group } from '../hooks/useSelectFormality';

export type SelectFormalityChoicesProps = {
    catalogs: Array<Catalog>;
    groups: Array<Group>;
    onCheckGroup: (groupId: number) => void;
    onUncheckGroup: (groupId: number) => void;
    onCheckCatalog: (catalogId: number) => void;
    onUncheckCatalog: (catalogId: number) => void;
};

const creationTitleFormalite = (title: string) => {
    const voyelle = ['A', 'E', 'I', 'O', 'U', 'a', 'e', 'i', 'o', 'u'];
    const titleFormalite = voyelle.includes(title.substring(0, 1))
        ? `Type d'${title.toLowerCase()}`
        : `Type de ${title.toLowerCase()}`;
    return titleFormalite;
};

const SelectFormalityChoices: React.FunctionComponent<SelectFormalityChoicesProps> = function (
    props,
) {
    const { catalogs, groups, onCheckGroup, onUncheckGroup, onCheckCatalog, onUncheckCatalog } =
        props;
    const checkedGroup = groups.filter((group) => group.checked).map((group) => group.id);
    let currentDisplayedGroup: number | null = null;
    const [showMoreGroups, setShowMoreGroups] = useState<boolean>(false);
    const theme = useTheme();
    const { palette, breakpoints } = theme;
    const downMdBreakpoint = useMediaQuery(breakpoints.down('md'));
    const downSmBreakpoint = useMediaQuery(breakpoints.down('sm'));
    const splitIndex = 8;

    return (
        <Box>
            <Box
                sx={{
                    marginTop: '16px',
                    marginBottom: '4px',
                    lineHeight: '24px',
                }}
            >
                <Typography variant="l2dbodys">Type de formalité</Typography>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: downMdBreakpoint ? '1fr 1fr' : '1fr 1fr 1fr 1fr',
                    width: '100%',
                }}
            >
                {groups.slice(0, splitIndex).map((group) => (
                    <Box
                        sx={{ padding: '2px' }}
                        key={`select-group-${group.id}`}
                    >
                        <SelectItem
                            label={group.label}
                            value={group.id}
                            isChecked={group.checked}
                            icone={group.icon || undefined}
                            design="block"
                            onChange={(groupId: number, checked: boolean) => {
                                if (checked) {
                                    onCheckGroup(groupId);
                                } else {
                                    onUncheckGroup(groupId);
                                }
                            }}
                        />
                    </Box>
                ))}
            </Box>
            {groups.slice(splitIndex).length > 0 && (
                <Box>
                    {groups.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button
                                variant="text"
                                onClick={() => setShowMoreGroups((v) => !v)}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: palette.primary.dark,
                                }}
                            >
                                <FaOptionIcon icon={!showMoreGroups ? faPlus : faMinus} />
                                <Typography variant="l2dbodys">
                                    {!showMoreGroups
                                        ? 'Afficher toutes les formalités'
                                        : 'Afficher moins de formalités'}
                                </Typography>
                            </Button>
                        </Box>
                    )}
                    {showMoreGroups && (
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                width: '100%',
                            }}
                        >
                            {groups.slice(splitIndex).map((group) => (
                                <Box
                                    sx={{ padding: '2px' }}
                                    key={`select-group-${group.id}`}
                                >
                                    <SelectItem
                                        label={group.label}
                                        value={group.id}
                                        isChecked={group.checked}
                                        icone={group.icon || undefined}
                                        design="inline"
                                        onChange={(groupId: number, checked: boolean) => {
                                            if (checked) {
                                                onCheckGroup(groupId);
                                            } else {
                                                onUncheckGroup(groupId);
                                            }
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginTop: '40px',
                    gap: '4px',
                }}
            >
                {catalogs
                    .filter((catalog) => checkedGroup.includes(catalog.groupId))
                    .sort((a, b) => {
                        if (a.groupSortOrder === b.groupSortOrder) {
                            return 0;
                        }

                        return a.groupSortOrder < b.groupSortOrder ? -1 : 1;
                    })
                    .map((catalog) => {
                        const selectItem = (
                            <Box
                                key={`select-catalog-${catalog.id}`}
                                sx={{
                                    flexGrow: 1,
                                    maxWidth: downSmBreakpoint ? '100%' : '50%',
                                    flexDirection: downSmBreakpoint ? 'column' : 'row',
                                }}
                            >
                                <SelectItem
                                    label={catalog.label}
                                    value={catalog.id}
                                    isChecked={catalog.checked}
                                    design="inline"
                                    onChange={(catalogId: number, checked: boolean) => {
                                        if (checked) {
                                            onCheckCatalog(catalogId);
                                        } else {
                                            onUncheckCatalog(catalogId);
                                        }
                                    }}
                                />
                            </Box>
                        );

                        if (catalog.groupId !== currentDisplayedGroup) {
                            const firstItem = currentDisplayedGroup === null;
                            currentDisplayedGroup = catalog.groupId;

                            return (
                                <React.Fragment key={`select-catalog-label-${catalog.id}`}>
                                    <Typography
                                        component="h2"
                                        variant="l2dcaption"
                                        sx={{
                                            flexBasis: '100%',
                                            flexGrow: 2,
                                            width: '100%',
                                            lineHeight: '25px',
                                            marginBottom: '4px',
                                            marginTop: !firstItem ? '1em' : '0',
                                        }}
                                    >
                                        {creationTitleFormalite(catalog.groupLabel)}
                                    </Typography>
                                    {selectItem}
                                </React.Fragment>
                            );
                        }

                        return selectItem;
                    })}
            </Box>
        </Box>
    );
};

export default SelectFormalityChoices;
