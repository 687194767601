import React, { useState } from 'react';
import { castIriToId } from '@europrocurement/l2d-utils';
import { jsonForm } from '@europrocurement/flexy-form/utils/types/formTypes';
import { CustomOptionResolvers } from '@europrocurement/flexy-form';
import {
    FormulaireFormaliteApiObject,
    useFormaliteService,
    useFormulaireService,
} from '@europrocurement/l2d-domain';
import { FormulaireFormaliteJsonld } from '@europrocurement/l2d-domain/openApi/ApiFormulaire';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import { Previous, Stepper } from '@europrocurement/flexy-components';
import { useSnackbar } from 'notistack';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { JsonForm } from '../../JsonForms';
import { useParcoursFormalite } from '../provider';
import { useListeSelectionDataResolver } from '../../JsonForms/hooks/useListeSelectionDataResolver';
import { addDataToCollection } from '../provider/functions';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';

const StepFormulaires: React.FunctionComponent = function () {
    const { enqueueSnackbar } = useSnackbar();
    const { getCurrentDossier, getCurrentCollection, containerWidth, currentCatalog } =
        useParcoursFormalite();
    const dossier = getCurrentDossier();
    const collection = getCurrentCollection();
    const { formulairesApi } = useFormulaireService();
    const { formaliteApi, collectionApi } = useFormaliteService();
    const navigate = useNavigate();

    const [form, setForm] = useState<FormulaireFormaliteApiObject | null>(null);

    const createListeSelectionDataResolver = useListeSelectionDataResolver();
    const startPage = 0;

    const [currentPage, setCurrentPage] = useState(startPage);
    const [pagelenth, setPagelenth] = useState<number | undefined>(startPage);

    const defaultValues = {};

    const { setCustomHeader } = useFullLayout();

    React.useEffect(() => {
        if (pagelenth) {
            console.log(pagelenth);
            console.log(15 + (85 / pagelenth - 1) * currentPage);
        }
        setCustomHeader(
            <Box sx={{ width: '100%', padding: 'auto' }}>
                <Container maxWidth="md">
                    <Stepper
                        steps={[
                            {
                                name: 'step_company',
                                text: 'Société',
                                progress: 100,
                            },
                            {
                                name: 'step_formality',
                                text: 'Formalité',
                                progress: pagelenth ? 15 + (70 / pagelenth) * currentPage : 15,
                            },
                            {
                                name: 'step_documents',
                                text: 'Documents',
                                progress: 0,
                            },
                            {
                                name: 'step_summary',
                                text: 'Récapitulatif',
                                progress: 0,
                            },
                        ]}
                    />
                </Container>
            </Box>,
        );
        return () => {
            setCustomHeader(null);
        };
    }, [currentPage, pagelenth, setCustomHeader]);

    React.useEffect(() => {
        if (!form && dossier) {
            const fetchForm = async () => {
                const resFormality = await formaliteApi.apiFormalitesIdGet({
                    id: `${castIriToId(dossier.formalite as unknown as string)}`,
                });
                console.log(resFormality);
                if (resFormality.data.formulaire) {
                    const resFormulaire = await formulairesApi.apiFormulaireFormalitesIdGet({
                        id: castIriToId(resFormality.data.formulaire),
                    });
                    if (resFormulaire.data) {
                        setForm(resFormulaire.data as FormulaireFormaliteJsonld);
                    }
                } else {
                    enqueueSnackbar('no formulaire on formality', { variant: 'error' });

                    throw new Error('no formulaire on formality');
                }
            };
            fetchForm();
        }
    }, [dossier, enqueueSnackbar, form, formaliteApi, formulairesApi]);

    if (!form || !dossier || !collection || !collection.id) {
        return null;
    }

    let formjson;
    if (form) {
        const formjsonstring = form.formulaire;
        // if (formDirigeant) {
        //     const formjsonstring = formDirigeant.formulaire;
        if (formjsonstring) {
            formjson = JSON.parse(formjsonstring);
        }
    }

    const onPrevious = () => {
        navigate(parcoursFormalitePathResolver('stepClient'));
    };

    const customOptionResolvers: CustomOptionResolvers = {
        listeSelectionDataResolver: createListeSelectionDataResolver(form),
    };
    return (
        <Container maxWidth={containerWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <CurrentFormalityAndClient />
            </Box>
            {currentPage === 0 ? <Previous onClick={onPrevious} /> : null}
            {formjson ? (
                <JsonForm
                    onChange={async (curr, newPagelenth, data) => {
                        console.log(curr, newPagelenth, JSON.stringify(data));
                        if (curr === newPagelenth) {
                            if (collection.id) {
                                await addDataToCollection(
                                    collection.id,
                                    collectionApi,
                                    JSON.stringify(data),
                                );
                                navigate(parcoursFormalitePathResolver('stepDocs'));
                            }
                        } else {
                            setCurrentPage(curr);
                            setPagelenth(newPagelenth);
                        }
                    }}
                    visibility={currentCatalog?.offre?.visibilite || 'FULL'}
                    debugMode={false}
                    json={formjson as jsonForm}
                    startPage={startPage}
                    defaultValues={defaultValues}
                    customOptionResolvers={customOptionResolvers}
                />
            ) : (
                <h1>Aucun formulaire</h1>
            )}
        </Container>
    );
};

export default StepFormulaires;
