import React, { FunctionComponent } from 'react';

import { Box, Typography, Card } from '@mui/material';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { mimetypeToIcone, statusToColor } from '../DisplayFile';
import { useUploader } from '../../organisms/Uploader/useUploader';

import FileUpload, { type FileUploadProps } from '../../molecules/FileUpload';

export type TypedRequiredFileProps = {
    libelle: string;
    // id: number;
    accept?: string;
    formatInfo?: string;
    maxMbSize?: number;
};

const TypedRequiredFile: FunctionComponent<TypedRequiredFileProps> = function ({
    // id,
    libelle,
    accept = 'application/pdf',
    formatInfo = '.pdf',
    maxMbSize = undefined,
}) {
    const { addFiles } = useUploader();

    const fileUploadProp: FileUploadProps = {
        accept,
        formatInfo,
        maxMbSize,
        inline: true,
        height: '36px',
        width: '70%',
        btnText: 'Sélectionez',
        dropText: 'ou glissez ici',
        border: false,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target.files?.length > 0) {
                addFiles(event.target.files);
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            addFiles(event.dataTransfer.files);
        },
    };

    return (
        <Card
            sx={{
                padding: '10px 15px 10px 15px',
                margin: '6px 0px 6px 0px',
                borderRadius: '12px',
                boxShadow: 'none',
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                gap="10px"
            >
                <Box
                    data-testid="test-id-status-color-box"
                    sx={{
                        backgroundColor: 'text.gris005',
                        padding: '8px',
                        borderRadius: '10px',
                        fontSize: '14px',
                        width: 36,
                        height: 36,
                    }}
                >
                    <FaOptionIcon
                        data-testid="test-id-mimetype-icone"
                        icon={mimetypeToIcone('application/pdf')}
                        color={statusToColor('idle')}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'grid',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        // sx={{ display: 'inline-block' }}
                        variant="l2dcaption"
                    >
                        {libelle}
                    </Typography>
                </Box>
                <FileUpload {...fileUploadProp} />
            </Box>
        </Card>
    );
};

export default TypedRequiredFile;
