import React, { FunctionComponent, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import {
    FilterDatatable,
    FilterOptionValue,
    FlexyFilterValue,
    SerializedFlexyFilterValue,
    SelectApiFilter,
    StaticSelectFilter,
} from '../DatatableHeader';
import FlexyFilterExpandable from '../FlexyFilterExpandable/FlexyFilterExpandable';
import FlexyTextFilter from '../FlexyTextFilter/FlexyTextFilter';
import FlexyBooleanFilter from '../FlexyBooleanFilter/FlexyBooleanFilter';
import FlexySelectFilter from '../FlexySelectFilter/FlexySelectFilter';
import FlexyFilterCollapsed from '../FlexyFilterCollapsed/FlexyFilterCollapsed';
import FlexyDateRangeFilter from '../FlexyDateRangeFilter';
import FlexyDateRangeWrapper from '../FlexyDateRangeWrapper';
import FlexySelectApiFilter from '../FlexySelectApiFilter';
import FlexySelectMultipleApiFilter from '../FlexySelectMultipleApiFilter/FlexySelectMultipleApiFilter';

const useFilter = () => {
    const theme = useTheme();

    const getWrapperComponent = (filter: FilterDatatable): FunctionComponent | null => {
        const map: Record<string, FunctionComponent> = {
            boolean: FlexyFilterCollapsed,
            dateRange: FlexyDateRangeWrapper,
        };

        return map[filter.type] || FlexyFilterExpandable;
    };

    const getWidgetComponent = (filter: FilterDatatable): FunctionComponent | null => {
        const map: Record<string, FunctionComponent> = {
            boolean: FlexyBooleanFilter,
            staticSelect: FlexySelectFilter,
            dateRange: FlexyDateRangeFilter,
            selectApi: FlexySelectApiFilter,
            selectMultipleApi: FlexySelectMultipleApiFilter,
        };

        return map[filter.type] || FlexyTextFilter;
    };

    const getFilterLabel = (
        filter: FilterDatatable,
        value: FlexyFilterValue,
    ): string | ReactNode[] => {
        if (value === null || value === undefined) {
            return filter.label;
        }

        const map: Record<
            string,
            (v: FlexyFilterValue | Array<FlexyFilterValue>) => string | ReactNode[]
        > = {
            boolean: (v: string | number | boolean | null) =>
                `${filter.label}:${v ? 'oui' : 'non'}`,
            staticSelect: (v: FlexyFilterValue) => {
                const choice = filter.options.find(
                    (option: FilterOptionValue) => String(option.value) === String(v),
                );

                if (!choice) {
                    return '';
                }

                return (filter as StaticSelectFilter).renderFilterList
                    ? filter.renderFilterList(choice.value)
                    : choice.label;
            },
            dateRange: (v: FlexyFilterValue) => {
                if (v?.startDate && v?.endDate && filter?.displayFormat) {
                    return filter.displayFormat(v.startDate, v.endDate);
                }

                const startAt = v?.startDate ? v.startDate.toLocaleDateString('FR-fr') : null;
                const endAt = v?.endDate ? v.endDate.toLocaleDateString('FR-fr') : null;

                return `${startAt} - ${endAt}`;
            },
            selectApi: (v: string | number | boolean | null) =>
                (filter as SelectApiFilter<Record<string, unknown>>).renderItem(v),
            selectMultipleApi: (v: Array<FlexyFilterValue>): string | ReactNode[] =>
                v.length > 0
                    ? [
                          filter.label,
                          <Box
                              key="flexy-chips-counter"
                              sx={{
                                  color: theme.palette.primary.dark,
                                  backgroundColor: theme.palette.secondary.light,
                                  display: 'flex',
                                  marginLeft: '5px',
                                  padding: '5px',
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '50%',
                                  lineHeight: '20px',
                                  alignItems: 'center',
                                  fontWeight: '500',
                              }}
                          >
                              {v.length}
                          </Box>,
                      ]
                    : [filter.label],
        };

        const suffix = map[filter.type] ? map[filter.type](value) : String(value);

        return suffix !== null && suffix !== undefined ? suffix : filter.label;
    };

    const serializeValue = (
        filter: FilterDatatable,
        value: FlexyFilterValue,
    ): SerializedFlexyFilterValue => {
        if (filter.type === 'dateRange') {
            return {
                startDate: value?.startDate.toString() || null,
                endDate: value?.endDate.toString() || null,
            };
        }

        return value;
    };

    const unserializeValue = (
        filter: FilterDatatable,
        value: SerializedFlexyFilterValue,
    ): FlexyFilterValue => {
        if (filter.type === 'dateRange') {
            if (!value?.startDate || !value?.endDate) {
                return null;
            }

            return {
                startDate: value?.startDate ? new Date(value.startDate) : null,
                endDate: value?.endDate ? new Date(value.endDate) : null,
            };
        }

        return value;
    };

    return {
        getFilterLabel,
        getWrapperComponent,
        getWidgetComponent,
        serializeValue,
        unserializeValue,
    };
};

export default useFilter;
