import {
    AdresseJsonldSousclientCreate,
    ContactJsonldSousclientCreate,
    ContactJsonldSousclientCreateCiviliteEnum,
    RelationSocieteTiersJsonldSousclientCreate,
    TiersSousclientJsonldSousclientCreate,
    TiersSousclientJsonldSousclientCreated,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { AxiosResponse } from 'axios';
import { useSociete, useTiersService } from '@europrocurement/l2d-domain';
import { useCurrentPrescriber } from '@europrocurement/l2d-modules/hooks/useCurrentPrescriber';

export type AddPrescriberFormData = {
    personnePhysique: Array<boolean>;
    siren: string;
    companyName: string;
    fullAddress: string;
    address: string;
    addressComplement: string | null;
    postalCode: string;
    city: string;
    country: string;
    civility: Array<number>;
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
    mobileNumber: string;
    venteIndirecte: Array<boolean>;
};

const useAddPrescriberClient = () => {
    const { sousClientsApi } = useTiersService();
    const prescriber = useCurrentPrescriber();
    const xIdSociete = useSociete();

    if (!prescriber || !prescriber.id) {
        throw new Error('No prescriber id');
    }

    const getAddress = (formData: AddPrescriberFormData): AdresseJsonldSousclientCreate | null => {
        const { address, addressComplement, postalCode, city, country } = formData;

        return {
            adresse1: address,
            adresse2: addressComplement || '',
            adresse3: undefined,
            adresse4: undefined,
            codePostal: postalCode,
            ville: city,
            codePays: country,
        };
    };

    const getRelationSociete = (): RelationSocieteTiersJsonldSousclientCreate | null => ({
        societe: { id: xIdSociete },
    });

    const getContact = (formData: AddPrescriberFormData): ContactJsonldSousclientCreate | null => {
        const { civility, lastName, firstName, email, phoneNumber, mobileNumber } = formData;

        return {
            civilite: (civility && civility[0]
                ? Number(civility[0])
                : null) as ContactJsonldSousclientCreateCiviliteEnum,
            nomContact: lastName,
            prenomContact: firstName,
            telephonesContact: phoneNumber ? [{ telephone: phoneNumber }] : [],
            emailsContact: email ? [{ email }] : [],
            mobilesContact: mobileNumber ? [{ mobile: mobileNumber }] : [],
            faxsContact: [],
        };
    };

    const getPayload = (formData: AddPrescriberFormData): TiersSousclientJsonldSousclientCreate => {
        const contact = getContact(formData);
        const address = getAddress(formData);
        const relationSociete = getRelationSociete();

        return {
            raisonSociale: formData.companyName,
            siren: formData.siren,
            siret: undefined,
            personnePhysique: formData.personnePhysique[0],
            demo: false,
            venteIndirecte: formData.venteIndirecte[0],
            adresses: address ? [address] : [],
            origine: { id: 13 },
            idPrescripteur: Number(prescriber.id),
            contacts: contact ? [contact] : [],
            relationsSocietes: relationSociete ? [relationSociete] : [],
        };
    };

    const handleForm = async (
        formData: AddPrescriberFormData,
    ): Promise<AxiosResponse<TiersSousclientJsonldSousclientCreated>> => {
        const payload = getPayload(formData);

        return sousClientsApi.createSousclientTiersCollection({
            xIdSociete,
            tiersSousclientJsonldSousclientCreate: payload,
        });
    };

    const isTiersNonFiable = (): boolean => {
        const companyInfo = prescriber.relationsSocietes.find(
            (info) => info?.societe?.id === xIdSociete,
        );

        return companyInfo?.tiersNonFiable || false;
    };

    const isVenteIndirecte = (): boolean =>
        prescriber.venteIndirecte === true || isTiersNonFiable();

    return { handleForm, isTiersNonFiable, isVenteIndirecte };
};

export default useAddPrescriberClient;
