import React, { useContext, useEffect, useState } from 'react';
import { Contact } from '@europrocurement/l2d-domain';
import { DossierContext } from '../../provider';
import PeoplePreview from '../PeoplePreview';

type ContactColumnProps = {
    contactIri: string;
};

const ContactColumn: React.FunctionComponent<ContactColumnProps> = function (props) {
    const { contactIri } = props;
    const [contact, setContact] = useState<Contact | null>(null);
    const { getContact } = useContext(DossierContext);

    useEffect(() => {
        getContact(contactIri)
            .then((response: Contact) => {
                setContact(response);
            })
            .catch(() => {
                setContact(null);
            });
    }, [contactIri, getContact]);

    return contact ? (
        <PeoplePreview
            firstName={contact.prenomContact}
            lastName={contact.nomContact}
        />
    ) : null;
};

export default ContactColumn;
