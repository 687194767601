import { LazyBreadcrumb, ProgressBar } from '@europrocurement/flexy-components';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { ColumnDatatable, StoreDatatable } from '@europrocurement/flexy-datatable';
import {
    DossierFormalite,
    DossierFormaliteSelector,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import ActionMenu from '../../components/liste/ActionMenu';
import FormalisteColumn from '../../components/liste/FormalisteColumn';
import ContactColumn from '../../components/liste/ContactColumn';
import StatusColumn from '../../components/liste/StatusColumn';

const ListeDossiers: React.FunctionComponent = function () {
    const datasource: DataSource<DossierFormalite> = useSelector(DossierFormaliteSelector);
    const { xIdSociete } = useSelector(customizerSelector);
    const { getDossierFormalite } = useFormaliteService();
    const theme = useTheme();
    const { palette } = theme;

    const breadcrumbItems = [
        {
            link: '/accueil',
            icon: {
                icon: ['fasl', 'house'] as IconProp,
                sx: { display: 'flex', alignItems: 'center' },
            },
        },
        {
            link: '/dossiers',
            path: 'Mes dossiers',
        },
        'Mes dossiers',
    ];

    const columns: ColumnDatatable<DossierFormalite>[] = [
        {
            label: 'Collaborateur',
            render: ({ contact }) =>
                contact && <ContactColumn contactIri={contact as unknown as string} />,
            isDisplayed: true,
            sortable: true,
            field: 'contact.nom',
        },
        {
            label: 'Dossier',
            render: ({ libelleFormalite, numero }) => (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="l2dbodyxs"
                        component="span"
                    >
                        {libelleFormalite}
                    </Typography>
                    <Typography
                        variant="l2dbodyxs"
                        component="span"
                    >
                        {numero}
                    </Typography>
                </Box>
            ),
            isDisplayed: true,
            sortable: true,
            field: 'numero',
        },
        {
            label: 'Client',
            render: ({ societeDenomination }) => (
                <Typography
                    variant="l2dbodyxs"
                    component="span"
                >
                    {societeDenomination}
                </Typography>
            ),
            isDisplayed: true,
            sortable: true,
            field: 'societeDenomination',
        },
        {
            label: 'Étapes',
            render: () => (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ProgressBar
                        completion={10}
                        color={palette.primary}
                        minCompletion={10}
                    />
                    <Typography
                        variant="l2dbodyxs"
                        component="span"
                    >
                        Brouillon
                    </Typography>
                </Box>
            ),
            isDisplayed: true,
            sortable: true,
        },
        {
            label: 'Dates',
            render: ({ dateCreation }) => {
                if (!dateCreation) {
                    return null;
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                        }}
                    >
                        <FaOptionIcon icon={['fasl', 'calendar']} />
                        <Typography
                            variant="l2dbodyxs"
                            component="span"
                        >
                            {new Date(dateCreation).toLocaleDateString()}
                        </Typography>
                    </Box>
                );
            },
            isDisplayed: true,
            sortable: true,
            field: 'dateCreation',
        },
        {
            label: 'Notifications',
            render: ({ documents }) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <FaOptionIcon icon={['fasl', 'paperclip']} />
                        <Typography
                            variant="l2dbodyxs"
                            component="span"
                        >
                            {documents?.length || 0}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <FaOptionIcon icon={['fasl', 'message']} />
                        <Typography
                            variant="l2dbodyxs"
                            component="span"
                        >
                            0
                        </Typography>
                    </Box>
                </Box>
            ),
            isDisplayed: true,
            sortable: false,
        },
        {
            label: 'Statut',
            render: ({ statut }) =>
                statut && <StatusColumn statusIri={statut as unknown as string} />,
            isDisplayed: true,
            sortable: true,
            field: 'statut',
        },
        {
            label: 'Formaliste',
            render: ({ formaliste }) =>
                formaliste && <FormalisteColumn formalisteIri={formaliste as unknown as string} />,
            isDisplayed: true,
            sortable: true,
            field: 'formaliste',
        },
        {
            label: 'Actions',
            displayLabel: false,
            render: () => <ActionMenu />,
            isDisplayed: true,
            sortable: false,
        },
    ];

    return (
        <>
            <LazyBreadcrumb
                path={breadcrumbItems}
                separator=" / "
                sx={{ marginBottom: '1.5em' }}
            />
            <StoreDatatable
                dataSource={datasource}
                columns={columns}
                fetchData={getDossierFormalite}
                localStorageKey="FlexyStoreDatatable"
                observers={[xIdSociete]}
                sx={{ marginBottom: '2.25em' }}
            />
        </>
    );
};

export default ListeDossiers;
