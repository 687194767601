import React, { ChangeEvent, DragEvent, FunctionComponent, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { useSnackbar } from 'notistack';

export type FileUploadProps = {
    accept: string;
    formatInfo: string;
    width?: string;
    height?: string;
    maxMbSize?: number;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onDrop: (event: DragEvent<HTMLElement>) => void;
    inline?: boolean;
    disableFormat?: boolean;
    border?: boolean;
    btnText?: string;
    dropText?: string;
    additionalInfo?: React.ReactNode;
};

const FileUpload: FunctionComponent<FileUploadProps> = function ({
    accept,
    formatInfo,
    width = '100%',
    height = '214px',
    inline = false,
    onChange,
    onDrop,
    maxMbSize,
    border = true,
    btnText = 'Cliquez ici',
    dropText = 'ou déposez vos fichiers',
    disableFormat = false,
    additionalInfo = null,
}) {
    const [isHover, setIsHover] = useState<boolean>(false);

    const stopDefaults = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        Array.from(event.target.files).forEach((file) => {
            // console.log(file.size);
            if (maxMbSize !== undefined && file.size > maxMbSize * 1000000) {
                // console.log(maxMbSize);
                enqueueSnackbar(<Typography>Fichier trop lourd</Typography>, {
                    variant: 'error',
                });
                throw new Error('Fichier trop lourd');
            }
            // console.log(file.type);
            if (accept !== undefined && !accept.split(',').includes(file.type)) {
                // console.log(accept);
                enqueueSnackbar(<Typography>Format de fichier non compatible</Typography>, {
                    variant: 'error',
                });
                throw new Error('Format de fichier non compatible');
            }
        });
        onChange(event);
    };

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    const handleDrop = (event: DragEvent<HTMLElement>) => {
        stopDefaults(event);
        Array.from(event.dataTransfer.files).forEach((file) => {
            // console.log(file.size);
            if (maxMbSize !== undefined && file.size > maxMbSize * 1000000) {
                // console.log(maxMbSize);
                enqueueSnackbar(<Typography>Fichier trop lourd</Typography>, {
                    variant: 'error',
                });
                throw new Error('Fichier trop lourd');
            }
            // console.log(file.type);
            if (accept !== undefined && !accept.split(',').includes(file.type)) {
                // console.log(accept);
                enqueueSnackbar(<Typography>Format de fichier non compatible</Typography>, {
                    variant: 'error',
                });
                throw new Error('Format de fichier non compatible');
            }
        });
        onDrop(event);
    };

    const dragEvents = {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onDragEnter: (e: DragEvent) => {
            stopDefaults(e);
        },
        onDragLeave: (e: DragEvent) => {
            stopDefaults(e);
        },
        onDragOver: stopDefaults,
        onDrop: (e: DragEvent<HTMLElement>) => {
            handleDrop(e);
        },
    };

    return (
        <Box sx={{ width }}>
            <label
                htmlFor="file-upload"
                {...dragEvents}
            >
                <Box
                    height={height}
                    sx={
                        border
                            ? {
                                  border: 1,
                                  borderStyle: 'dashed',
                                  backgroundColor: 'text.gris005',
                                  borderColor: 'text.gris35',
                                  borderRadius: '12px',
                              }
                            : {}
                    }
                >
                    <Box
                        height="100%"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {inline ? null : (
                            <Typography color="GrayText">
                                <FontAwesomeIcon
                                    icon={faCloudArrowDown}
                                    bounce={isHover}
                                    size="3x"
                                />
                            </Typography>
                        )}
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                id="basic-button"
                                color="inherit"
                                component="label"
                            >
                                <Typography
                                    variant={inline ? 'l2dcaption' : 'l2dbodym'}
                                    color="primary.dark"
                                    sx={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {btnText}
                                </Typography>
                                {maxMbSize !== undefined ? (
                                    <input
                                        type="file"
                                        onChange={handleChange}
                                        accept={accept}
                                        size={maxMbSize * 1000000}
                                        multiple
                                        hidden
                                    />
                                ) : (
                                    <input
                                        type="file"
                                        onChange={handleChange}
                                        accept={accept}
                                        multiple
                                        hidden
                                    />
                                )}
                            </Button>
                            <Typography
                                variant={inline ? 'l2dcaption' : 'l2dbodym'}
                                sx={{
                                    fontWeight: 500,
                                }}
                            >
                                {dropText}
                            </Typography>
                        </Box>
                        {inline || disableFormat ? null : (
                            <Typography
                                variant="caption"
                                color="GrayText"
                            >
                                Formats: {formatInfo}{' '}
                                {maxMbSize ? `- Poids maximum :${maxMbSize}Mo` : ''}
                            </Typography>
                        )}
                        {additionalInfo}
                    </Box>
                </Box>
            </label>
        </Box>
    );
};

export default FileUpload;
