import { FormStructure } from '../components';
import { jsonPageSectionToStructure } from './structures';
import { jsonForm, jsonSection, visibilityType } from './types/formTypes';

const jsonPageSectionsToStructure: (
    sections: jsonSection[],
    visibility: visibilityType,
) => FormStructure[] = (sections, visibility) => {
    const sectionsFiltered = sections.filter((section) =>
        section.visibility ? section.visibility.includes(visibility) : true,
    );
    return sectionsFiltered.map((section) => jsonPageSectionToStructure(section));
};

const jsonFormToFormStructure: (
    json: jsonForm,
    page: number,
    visibility: visibilityType,
) => FormStructure[] = (json, page, visibility) => {
    const structure: FormStructure[] = [];

    if (json.pages[page] && json.pages[page].sections) {
        return jsonPageSectionsToStructure(json.pages[page].sections, visibility);
    }

    return structure;
};

export default jsonFormToFormStructure;
