import * as React from 'react';
import { Button, PaletteColor, Typography, useTheme } from '@mui/material';
import { FaOptionIcon, FaOptionIconProps } from '@europrocurement/l2d-icons';
import './L2DFSidebarAction.css';
import { Box } from '@mui/system';

export type L2DFSidebarActionProps = {
    label: string;
    icon: FaOptionIconProps;
    folded?: boolean;
    color?: PaletteColor;
    onClick: () => void;
};

const L2DFSidebarAction: React.FunctionComponent<L2DFSidebarActionProps> = function (props) {
    const theme = useTheme();
    const { label, icon, folded = false, color, onClick } = props;
    const mainColor = color || theme.palette.primary;
    const textColor = theme.palette.background.paper;
    const classNames = ['l2df-sidebar-action'];

    if (folded) {
        classNames.push('l2df-sidebar-action-folded');
        icon.size = 'lg';
    }

    return (
        <Button
            className={classNames.join(' ')}
            data-testid="l2df-sidebar-action"
            onClick={onClick}
            sx={{
                backgroundColor: mainColor.dark,
                padding: '16px 24px 16px 24px ',
                color: textColor,
                verticalAlign: 'middle',
                ':hover': { backgroundColor: mainColor.main },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    verticalAlign: 'middle',
                }}
            >
                <FaOptionIcon
                    {...icon}
                    sx={
                        folded
                            ? { ...icon.sx, marginRight: '0px' }
                            : { ...icon.sx, marginTop: '15px', marginRight: '8px' }
                    }
                />
                <Typography
                    variant="l2dbodys"
                    sx={{ lineHeight: 'auto' }}
                >
                    {folded ? '' : label}
                </Typography>
            </Box>
        </Button>
    );
};

export default L2DFSidebarAction;
