import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName, faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { Typography } from '@mui/material';
import { Box, lighten, useTheme } from '@mui/system';
import React, { CSSProperties, FunctionComponent } from 'react';

export type BaseItemProps = {
    label: string;
    value: string | number | boolean;
    isChecked?: boolean;
    isDisabled?: boolean | ((id: number) => boolean);
    onChange?: (value: BaseItemProps['value'], checked: boolean) => void;
};

export type SelectItemProps = BaseItemProps & {
    icone?: string;
    design?: 'block' | 'inline';
};

const SelectItem: FunctionComponent<SelectItemProps> = function (props) {
    const {
        label,
        value,
        icone,
        design = 'inline',
        isChecked = false,
        isDisabled = false,
        onChange = () => {},
    } = props;
    const theme = useTheme();

    const changecheck = () => {
        onChange(value, !isChecked);
    };

    let flexDirection: CSSProperties;
    let cssChecked: CSSProperties = {};
    let cssCheckedBox: CSSProperties;
    let cssIconeBox: CSSProperties;
    let cssDisabled: CSSProperties = {};

    let iconeWithShape: boolean = false;
    let colorIcone = 'text.gris70';

    if (design === 'inline') {
        flexDirection = {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            height: '48px',
            padding: '4px 16px 4px 16px',
        };
        cssIconeBox = {
            fontSize: '18px',
            marginRight: '5px',
        };
        cssCheckedBox = {
            position: 'absolute',
            top: '15px',
            right: '5px',
        };
    } else {
        iconeWithShape = true;
        flexDirection = {
            flexDirection: 'column',
            height: '149px',
            justifyContent: 'space-around',
            padding: '10px',
            textAlign: 'center',
        };
        cssCheckedBox = {
            position: 'absolute',
            top: '5px',
            right: '5px',
        };
        cssIconeBox = {
            marginTop: '15px',
            fontSize: '28px',
        };
    }
    if (isDisabled) {
        cssDisabled = {
            borderColor: theme.palette.grey[300],
            background: theme.palette.grey[300],
            cursor: 'not-allowed',
        };
    } else if (isChecked) {
        cssChecked = {
            borderColor: theme.palette.primary.main,
            background: lighten(theme.palette.primary.main, 0.95),
            color: theme.palette.primary.dark,
        };
        colorIcone = theme.palette.primary.dark;
    } else {
        cssChecked = {
            borderColor: theme.palette.grey[300],
            background: theme.palette.background.paper,
        };
    }

    const iconeBox = (
        <Typography
            component="span"
            style={{
                ...cssIconeBox,
            }}
        >
            <FaOptionIcon
                icon={['fasl', icone as IconName]}
                color={colorIcone}
                withShape={iconeWithShape}
                shapeSize={48}
            />
        </Typography>
    );

    return (
        <Box
            style={{
                width: '100%',
                display: 'flex',
                gap: 1,
                border: '1px solid',
                borderRadius: '15px',
                cursor: 'pointer',
                position: 'relative',
                alignItems: 'center',
                ...flexDirection,
                ...cssDisabled,
                ...cssChecked,
            }}
            onClick={() => {
                if (!isDisabled) {
                    changecheck();
                }
            }}
        >
            {icone ? iconeBox : null}
            <Typography
                variant="l2dcaption"
                color="text.gris70"
            >
                {label}
            </Typography>
            {/* <FlexyInput
                type="checkbox"
                onChange={onChange}
            /> */}

            {isChecked ? (
                <Box
                    style={{
                        ...cssCheckedBox,
                    }}
                    className="selectItem-checked"
                    data-testid="test-id-selectItem-checked"
                >
                    <FaOptionIcon
                        icon={faCheck}
                        color={colorIcone}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default SelectItem;
