import React from 'react';
import { RouteObject } from 'react-router-dom';
import {
    parcoursFormaliteBasePath,
    parcoursFormaliteClientsStepPath,
    parcoursFormaliteDocsFirstStepPath,
    parcoursFormaliteDocsStepPath,
    parcoursFormaliteFormaliteStepPath,
    parcoursFormaliteFormStepPath,
    parcoursFormaliteRecapStepPath,
} from './constants';
import ParcoursFormaliteRoot from './ParcoursFormaliteRoot';
import StepSelectionFormalites from './steps/StepSelectionFormalites';
import StepSelectionClient from './steps/StepSelectionClient';
import StepFormulaires from './steps/StepFormulaires';
import StepDocuments from './steps/StepDocuments';
import StepRecap from './steps/StepRecap';
import StepDocFirst from './steps/StepDocFirst';

export const ParcoursFormaliteRouter: RouteObject = {
    path: parcoursFormaliteBasePath,
    element: <ParcoursFormaliteRoot />,
    children: [
        {
            path: parcoursFormaliteFormaliteStepPath,
            element: <StepSelectionFormalites />,
        },
        {
            path: parcoursFormaliteClientsStepPath,
            element: <StepSelectionClient />,
        },
        {
            path: parcoursFormaliteDocsFirstStepPath,
            element: <StepDocFirst />,
        },
        {
            path: parcoursFormaliteFormStepPath,
            element: <StepFormulaires />,
        },
        {
            path: parcoursFormaliteDocsStepPath,
            element: <StepDocuments />,
        },
        {
            path: parcoursFormaliteRecapStepPath,
            element: <StepRecap />,
        },
    ],
};
